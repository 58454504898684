import React from 'react';
import {useTranslation} from "react-i18next";

interface props {
confirmLoading: boolean
    isHide: boolean
    phone: string | undefined
    amount: string | undefined
    errors: any
}

const SubmitBtn: React.FC<props> = ({
    confirmLoading,
    isHide,
    phone,
    amount,
    errors
                                    }) => {
    const {t} = useTranslation()
    return (
        <button type={"submit"}
                disabled={confirmLoading}
                className={`language__continue refill__btn ${isHide ? 'refill__btn--hide' : ''} ${confirmLoading ? 'language__continue--disabled' : ''} ${errors?.amount || errors?.phone_number ? 'language__continue--disabled' : ''} ${phone && amount && amount !== '0' ? '' : "language__continue--disabled"}`}>
            {t('pay')}
        </button>
    );
};

export default SubmitBtn;