import React, {useEffect} from 'react'
import './foreign-operators.scss'
import {useTranslation} from "react-i18next";
import {Operator, SearchOperators} from "./components/index";
import darkNotFound from "../../assets/nothing-dark.svg";
import lightNotFound from "../../assets/nothing-light.svg";
import {useNavigate} from "react-router-dom";
import {backButtonClickedOff, backButtonClickedOn, onShowBackBtn} from "../../utils/telegram-events";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";
import {operatorsSelector} from "../../store/slices/operator-slice";
import Loader from "../../shared/loader/loader";
import {getCountriesApi} from "../../api/countries/countries";
import {getCountries} from "../../store/slices/countries-slice";
import {errorHandler} from "../../utils/error-handler";

interface Props {

}

const ForeignOperators: React.FC<Props> = () => {
    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {operators} = useSelector(operatorsSelector)
    const dispatch = useDispatch()


    useEffect(() => {

        const getCountriesHandler = () => {
            getCountriesApi().then((response) => {
                if (response?.data) {
                    dispatch(getCountries(response.data.result))
                }
            }).catch((e) => {
              errorHandler(e)
            })
        }
        getCountriesHandler()
        const onRedirect = () => {
            navigate(-1)
        }

        backButtonClickedOn(onRedirect)
        onShowBackBtn()

        return () => {
            backButtonClickedOff(onRedirect)
        }
    }, [])

    const filterData = (searchStr: string) => {
        console.log(searchStr)
    }

    if (!operators.loaded) return <Loader/>

    if (operators.errors.isError) return <p>
        {operators.errors.message}
    </p>

    return (
        <div className={'foreign__operators'}>
            <h2 className={'foreign__title'}>
                {t('foreignOperator')}
            </h2>
            <SearchOperators filterData={filterData}/>
            <div className={'foreign__operators-wrapper'}>
                {operators.entity.filter(({type}) => type !== 1).length > 0 ? operators.entity.filter(({type}) => type !== 1).map((operator) => (
                    <Operator operator={operator} key={operator.id}/>
                )) : <div className={'transactions__not-found'}>
                    <img src={theme.entity === 'dark' ? darkNotFound : lightNotFound} alt={""}/>
                    <p className={`transactions__text ${theme.entity === 'dark' ? "transactions__text--dark" : ''}`}>
                        {t('notFound')}
                    </p>
                </div>}
            </div>
        </div>
    )
}

export default ForeignOperators
