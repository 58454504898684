import axios from "axios";

const prodUrl = 'https://mymobile-api.globalpay.uz'

const devUrl = `https://mymobile-backend.globalpay.uz`

const makeRequest = axios.create({
    baseURL: devUrl,
    headers: {
        "Content-Type": "application/json"
    }
})




makeRequest.interceptors.request.use(
    async (config) => {
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

makeRequest.interceptors.response.use(
    async (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)

    },
)

export default makeRequest