import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {routes} from "./routes";
import Layout from "./hoc/layout/layout";




function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout/>}>
                    {routes.map(({
                                     path,
                                     component
                                 }) => (
                        <Route key={path} path={path} element={component}/>
                    ))}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
