import React, {useState} from 'react'
import {useTranslation} from "react-i18next";
import './change-language.scss'
import {Col, message, Row} from "antd";
import LanguagesBtn from "./components/languages-btn";
import {ILanguage, languagesCodes} from "../../interfaces/languages/languages";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createUser, updateUser} from "../../api/users/users";
import {useDispatch, useSelector} from "react-redux";
import {setUser, userSelector} from "../../store/slices/user-slice";
import {defaultUser} from "../../constants/user";
import Loader from "../../shared/loader/loader";
import {errorHandler} from "../../utils/error-handler";


interface Props {

}

declare global {
    interface Window {
        Telegram: any;
    }
}

const ChangeLanguage: React.FC<Props> = () => {
    const [searchParams] = useSearchParams()
    const {user} = useSelector(userSelector)
    const {i18n, t} = useTranslation()
    const [selectedLanguage, setSelectedLanguage] = useState<languagesCodes | null | any>(searchParams.get('default') && user.entity ? user.entity.lang : null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)


    const updateUserHandler = () => {
        updateUser(selectedLanguage).then((response) => {
            if (response?.data) {
                dispatch(setUser(response.data.result))
                i18n.changeLanguage(response.data.result.lang)
                navigate('/')
            }
        }).catch((e) => {
            errorHandler(e)
        }).finally(() => setConfirmLoading(false))
    }

    const createUserHandler = () => {
        createUser(defaultUser(selectedLanguage)).then((response) => {
            if (response?.data) {
                dispatch(setUser(response.data.result))
                i18n.changeLanguage(response.data.result.lang)
                navigate('/')
            }
        }).catch((e) => {
            errorHandler(e)
        }).finally(() => setConfirmLoading(false))
    }


    const changeLanguage = () => {
        if (selectedLanguage) {
            setConfirmLoading(true)
            if (user?.entity?.id) {
                updateUserHandler()
            } else {
                createUserHandler()
            }
        }
    }

    const languageHandler = (language: languagesCodes) => {
        setSelectedLanguage(language)
        i18n.changeLanguage(language)
    }

    const languages: ILanguage[] =
        [
            {
                code: 'ru',
                icon: '/media/flags/ru.svg',
                title: 'ru'
            },
            {
                code: 'uz',
                icon: '/media/flags/uz.svg',
                title: 'uz'
            },
            {
                code: 'en',
                icon: '/media/flags/en.svg',
                title: 'en'
            }
        ]


    return (
        <div className={`languages`}>
            {confirmLoading && <Loader/>}
            <div className={`languages__info`}>
                <Row align={'middle'} justify={'center'}>
                    <Col>
                        <svg width="30" height="30" viewBox="0 0 400 400" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="400" height="400" rx="200" fill="#5390D9"/>
                            <path d="M223.555 290V184.444H176.889V290H223.555Z" fill="#56CFE1"/>
                            <path d="M134.667 290V110H88V290H134.667Z" fill="white"/>
                            <path d="M312.444 290V110H265.778V290H312.444Z" fill="white"/>
                        </svg>
                    </Col>
                    <Col>
                        <h1 className={`languages__title`}>
                            My Mobile
                        </h1>
                    </Col>
                </Row>
                <div className={'languages__form'}>
                    <h2 className={`languages__subtitle`}>
                        {t('chooseLanguageContinue')}
                    </h2>
                    {languages.map(({
                                        icon,
                                        title,
                                        code
                                    }) => (
                        <LanguagesBtn key={code} code={code} selectedLanguage={selectedLanguage}
                                      languageHandler={languageHandler} title={title} icon={icon}/>
                    ))}
                </div>
            </div>
            <button disabled={confirmLoading} onClick={changeLanguage}
                    className={`language__continue ${selectedLanguage === null ? 'language__continue--disabled' : ""}`}>
                {t(searchParams.get('default') ? 'choose' : 'continue')}
            </button>
        </div>
    )
}

export default ChangeLanguage
