import {IOperator} from "../interfaces/operators/operators";
import {IThemeType} from "../interfaces/theme/theme";

export const findImage = (foundOperator: IOperator | undefined, theme: IThemeType) => {
    if (foundOperator) {
        return foundOperator.images[theme]
    }
    return `/media/gallery-${theme}.jpg`
}


export const findInputImage = (foundOperator: IOperator | undefined, theme: IThemeType) => {
    if (foundOperator) {
        return foundOperator.images[theme]
    } return `/media/gallery-${theme}.jpg`
}