import React from 'react'
import './languages-btn.scss'
import {useTranslation} from "react-i18next";
import {languagesCodes} from "../../../interfaces/languages/languages";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../store/slices/theme-slice";
import LazyLoadImageComponent from "../../../shared/lazy-load-image/lazy-load-image";

interface Props {
    code: languagesCodes
    icon: string
    title: string
    languageHandler: (lang: languagesCodes) => void
    selectedLanguage: languagesCodes | null
}

const LanguagesBtn: React.FC<Props> = ({
                                           title,
                                           icon,
                                           code,
                                           languageHandler,
                                           selectedLanguage
                                       }) => {

    const {t} = useTranslation()

    const {theme} = useSelector(themeSelector)
    const onChangeLanguage = () => {
        languageHandler(code)
    }

    return (
        <button onClick={onChangeLanguage}
                className={`language__btn ${theme.entity === 'dark' ? "language__btn--dark" : ""} ${selectedLanguage === code ? 'language__btn--active' : ""}`}>
            <span className={'language__btn--img'}><LazyLoadImageComponent src={icon} alt={title}/></span> <span
            className={'language__btn--text'}>{t(title)}</span>
        </button>
    )
}

export default LanguagesBtn
