import React from 'react';
import {spacesRegEx} from "../../../../../../constants/regex";
import {Field, IPaymentFields} from "../../../../../../interfaces/payment/payment";
import {paymentFieldSelector, resetOperator, setPaymentFields} from "../../../../../../store/slices/payment-form-slice";
import {PatternFormat} from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {operatorsSelector} from "../../../../../../store/slices/operator-slice";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {useTranslation} from "react-i18next";
import {findInputImage} from "../../../../../../utils/findImage";


interface Props {
    clearErrors: (error: Field) => void
    setError: (type: Field, options: any) => void
    errors: any
    setValue: (name: Field, value: string) => void
    isHide: boolean
}

const PhoneInput: React.FC<Props> = ({
                                         clearErrors,
                                         setError,
                                         errors,
                                         setValue,
                                         isHide
                                     }) => {
    const {paymentFields} = useSelector(paymentFieldSelector)
    const {operators} = useSelector(operatorsSelector)
    const {theme} = useSelector(themeSelector)
    const dispatch = useDispatch()
    const {t} = useTranslation()


    return (
        <PatternFormat
            allowEmptyFormatting
            format={paymentFields.entity.operator ? paymentFields.entity.operator.options.phoneNumber.mask : '+### ## ### ## ##'}
            mask={' '}
            onValueChange={(values, sourceInfo) => {
                clearErrors('phone_number')
                const prefix = values.value.slice(0,3)
                const slicedCode = values.value.slice(3, 5).trim().replace(spacesRegEx, '')
                const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode) && item.options.phoneNumber.prefix.replace('+', '') === prefix)
                const fields: IPaymentFields = {
                    phone: `+${values.value}`,
                    icon: findInputImage(paymentFields.entity.operator, theme.entity),
                    operator: foundOperator
                }
                if (values.value.length < 3) {
                    dispatch(resetOperator())
                }
                if (slicedCode.length > 1 && !foundOperator && !paymentFields.isSet) {
                    setError('phone_number', {
                        type: 'manual',
                        message: `${t('payment.operatorNotFound')}`
                    })
                    dispatch(resetOperator())
                }
                if (foundOperator && !paymentFields.isSet) {
                    dispatch(setPaymentFields(fields))
                }
                setValue('phone_number', `+${values.value}`)
            }}
            onBlur={(e: any) => {
                const prefix = e.target.value.slice(0,4)
                const value = e.target.value.replace(spacesRegEx, '')
                const slicedCode = value.slice(4, 6).trim().replace(spacesRegEx, '')
                const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode) && item.options.phoneNumber.prefix === prefix)
                const fields: IPaymentFields = {
                    phone: value,
                    icon: findInputImage(paymentFields.entity.operator, theme.entity),
                    operator: foundOperator
                }

                if (value.length < 6) {
                    setError('phone_number', {
                        type: 'manual',
                        message: `${t('payment.incorrect')}`
                    })
                }

                if (paymentFields.entity.operator && paymentFields.isSet) {
                    const regExp = new RegExp(paymentFields.entity.operator.options.phoneNumber.validation)
                    if (regExp.test(value)) {
                        clearErrors('phone_number')
                    } else {
                        setError('phone_number', {
                            type: 'manual',
                            message: `${t('payment.incorrect')}`
                        })
                    }
                }
                if (foundOperator && !paymentFields.isSet) {
                    const regExp = new RegExp(foundOperator.options.phoneNumber.validation)
                    if (regExp.test(value)) {
                        clearErrors('phone_number')
                    } else {
                        setError('phone_number', {
                            type: 'manual',
                            message: `${t('payment.incorrect')}`
                        })
                    }
                    dispatch(setPaymentFields(fields))
                }
            }}
            prefix={'+'}
            value={paymentFields.entity.phone}

            className={`phone__input ${isHide ? 'phone__input--small' : ''} ${errors?.phone_number ? "error__color" : ""} ${theme.entity === 'dark' ? 'phone__input--dark' : ""}`}
        />
    );
};

export default PhoneInput;