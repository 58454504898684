import {IOperator, IOperatorsState} from "../../interfaces/operators/operators";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: IOperatorsState = {
    operators: {
        entity: [],
        errors: {
            isError: false,
            message: ''
        },
        confirmLoading: false,
        loaded: false
    }
}


/*  Thunk */


export const operatorsSelector = (state: { operators: IOperatorsState }) => state.operators


const operatorsSlice = createSlice({
    name: 'Operators',
    initialState,
    reducers: {
        setOperators: (state, action: PayloadAction<IOperator[]>) => {
            state.operators.entity = action.payload
            state.operators.loaded = true
            state.operators.errors.isError = false
        },
        setOperatorsError: (state, action: PayloadAction<string>) => {
            state.operators.loaded = true
            state.operators.errors.isError = true
            state.operators.errors.message = action.payload
        },
    }
})


export const {
    setOperators,
    setOperatorsError
} = operatorsSlice.actions


export default operatorsSlice.reducer





