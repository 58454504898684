import {IPagination, IPayment, IPaymentState} from "../../interfaces/payment/payment";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: IPaymentState = {
    payments: {
        entity: [],
        loaded: false,
        errors: {
            isError: false,
            message: ''
        },
        pagination: {
            nextPage: false,
            prevPage: false,
            page: 1,
            pageSize: 20,
            maxPage: 0,
            itemCount: 0
        }
    },
    payment: {
        entity: undefined,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}


export const paymentsSelector = (state: { payments: IPaymentState }) => state.payments


const paymentsSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPayment: (state, action: PayloadAction<IPayment[]>) => {
            state.payments.entity = action.payload
            state.payments.loaded = true
            state.payments.errors.isError = false
        },
        setPaymentError: (state, action: PayloadAction<string>) => {
            state.payments.loaded = true
            state.payments.errors.isError = true
            state.payments.errors.message = action.payload
        },
        addPayment: (state, action: PayloadAction<IPayment>) => {
            const newState = state.payments.entity
            state.payments.pagination = {
                ...state.payments.pagination,
                itemCount: state.payments.pagination.itemCount + 1
            }
            newState.unshift(action.payload)
            state.payments.entity = newState
            state.payments.loaded = true
            state.payments.errors.isError = false
        },
        editPayment: (state, action: PayloadAction<IPayment>) => {
            const newState = state.payments.entity.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload
                }
                return item
            })
            state.payments.entity = newState
            state.payments.loaded = true
            state.payments.errors.isError = false
        },
        setPaginationPayments: (state, action: PayloadAction<IPagination>) => {
            state.payments.pagination = action.payload
        },
        extendPayments: (state,action: PayloadAction<IPayment[]>) => {
            const newState = state.payments.entity.concat(action.payload)
            state.payments.entity = newState
        },
        resetPayments: (state) => {
            state.payments = initialState.payments
        }
    }
})


export const {
    setPayment,
    setPaymentError,
    addPayment,
    editPayment,
    resetPayments,
    setPaginationPayments,
    extendPayments,

} = paymentsSlice.actions



export default paymentsSlice.reducer