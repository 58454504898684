import {message} from "antd";
import {AxiosError} from "axios";


export function errorHandler (e: AxiosError<{error: {body?: {message?:string}}}>, callback?: () => void) {
    if (e?.response?.data?.error?.body?.message) {
        message.error(e.response.data.error.body.message)
        if (callback) {
            callback()
        }
    }
}

