import {combineReducers, configureStore} from '@reduxjs/toolkit'
import contactFields from './slices/contact-form-slice'
import paymentFields from './slices/payment-form-slice'
import theme from './slices/theme-slice'
import operators from './slices/operator-slice'
import user from './slices/user-slice'
import contacts from './slices/contacts-slice'
import payments from './slices/payment-slice'
import countries from './slices/countries-slice'


import {useDispatch} from "react-redux"
import {IRootState} from "../interfaces";

const reducer = combineReducers<IRootState>({
    contactFields: contactFields,
    paymentFields: paymentFields,
    theme: theme,
    operators: operators,
    user: user,
    contacts: contacts,
    payments: payments,
    countries: countries
})

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV === "development",
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()


export default store