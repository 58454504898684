import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IThemeState, IThemeType} from "../../interfaces/theme/theme";


const initialState: IThemeState = {
    theme: {
        entity: window.Telegram.WebApp.colorScheme
    }
}


export const themeSelector = (state: {theme : IThemeState}) => state.theme



const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<IThemeType>) => {
            state.theme.entity = action.payload
        }
    }
})


export const {setTheme} = themeSlice.actions


export default themeSlice.reducer