import React, {useEffect, useState} from 'react'
import {ConfigProvider, Drawer} from "antd";
import {useTranslation} from "react-i18next";
import '../../refill-form.scss'
import {Operator, SearchOperators} from "../../../../../foreign-operators/components";
import darkNotFound from "../../../../../../assets/nothing-dark.svg";
import lightNotFound from "../../../../../../assets/nothing-light.svg";
import {useSelector} from "react-redux";
import {operatorsSelector} from "../../../../../../store/slices/operator-slice";
import Loader from "../../../../../../shared/loader/loader";
import {paymentFieldSelector} from "../../../../../../store/slices/payment-form-slice";
import {contactFieldsSelector} from "../../../../../../store/slices/contact-form-slice";
import {IOperator} from "../../../../../../interfaces/operators/operators";
import {themeSelector} from "../../../../../../store/slices/theme-slice";

interface Props {
    isHide?: boolean
    isContact?: boolean
}

const OperatorsDrawer: React.FC<Props> = ({
                                              isHide,
                                              isContact
                                          }) => {
    const {t} = useTranslation()
    const {operators} = useSelector(operatorsSelector)

    const [data, setData] = useState<IOperator[]>(operators.entity)
    const {theme} = useSelector(themeSelector)
    const [open, setOpen] = useState<boolean>(false);
    const {paymentFields} = useSelector(paymentFieldSelector)
    const {contactFields} = useSelector(contactFieldsSelector)

useEffect(() => {
    setData(operators.entity)
}, [operators.entity])
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const title = () => {
        if (isContact) {
            if (contactFields.entity.operator) {
                return contactFields.entity.operator.title
            }
        }
        if (!isContact && paymentFields.entity.operator) {
            return paymentFields.entity.operator.title

        }
        return t('mobileOperator')
    }

    const filterData = (searchStr: string) => {
        const newData = operators.entity.filter(({title}) => title.toLowerCase().includes(searchStr.toLowerCase()))
        if (searchStr.length) {
            setData(newData)
            return
        }
        setData(operators.entity)
    }


    if (operators.errors.isError) return <p>
        {operators.errors.message}
    </p>


    return (
        <>

            <label onClick={showDrawer} className={`form__label ${isHide ? 'form__label--small' : ''}`}>
                <span>
{title()}
                </span>
                <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.08317 1.25L4.99984 5.33333L0.916504 1.25" stroke="#676A6F" strokeWidth="1.6"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

            </label>
            {!operators.loaded ? <Loader/> : <ConfigProvider
                theme={{
                    token: {
                        colorBgElevated: 'var(--tg-theme-bg-color)',
                        paddingLG: 16
                    },
                }}
            >
                <Drawer
                    placement={'bottom'}
                    closable={false}
                    onClose={onClose}
                    title={
                        <>
                            <h2 className={`foreign__title ${theme.entity === 'dark' ? 'foreign__title--dark' : ""}`}>
                                {t('mobileOperators')}
                            </h2>
                            <SearchOperators filterData={filterData} isDrawer={true}/>
                        </>
                    }
                    open={open}
                    key={'bottom'}
                    width={250}
                >

                    {data.length > 0 ? data.map((operator) => (
                        <Operator isContact={isContact} isDrawer={true} onClose={onClose} operator={operator}
                                  key={operator.id}/>
                    )) : <div className={'transactions__not-found'}>
                        <img src={theme.entity === 'dark' ? darkNotFound : lightNotFound} alt={""}/>
                        <p className={`transactions__text ${theme.entity === 'dark' ? "transactions__text--dark" : ''}`}>
                            {t('noMatch')}
                        </p>
                    </div>}
                </Drawer>
            </ConfigProvider>}
        </>
    )
}

export default OperatorsDrawer
