import React, {useEffect, useRef, useState} from 'react'
import './index.scss'
import {Col, Row} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Operators from "./components/operators/operators";
import ActionButtons from "./components/action-buttons/action-buttons";
import Transactions from "./components/transactions/transactions";
import {onHideBackBtn} from "../../utils/telegram-events";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";
import ShowToggler from "../../shared/show-toggler/show-toggler";

interface Props {

}

const Index: React.FC<Props> = () => {

    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()
    const [isHide, setIsHide] = useState<boolean>(false)

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {i18n} = useTranslation()


    const mainRef = useRef<null | HTMLDivElement>(null)

    const onToggle = () => setIsHide(!isHide)
    const show = () => setIsHide(false)
    const hide = () => setIsHide(true)


    useEffect(() => {
        if (mainRef?.current) {
            const onWheel = (e: WheelEvent) => {
                const dy = e.deltaY
                if (dy > 0) {
                    hide()
                }
            }
            let touchstartY = 0
            let touchendY = 0
            const checkDirection = () => {
                if (touchendY < touchstartY) {
                    hide()
                }

            }

            const onTouchStart = (e: TouchEvent) => {
                touchstartY = e.changedTouches[0].screenY
            }

            const onTouchEnd = (e: TouchEvent) => {
                touchendY = e.changedTouches[0].screenY
                checkDirection()
            }


            mainRef.current?.addEventListener('wheel', onWheel)
            mainRef.current?.addEventListener('touchstart', onTouchStart)
            mainRef.current?.addEventListener('touchend', onTouchEnd)
            onHideBackBtn()
            return () => {
                mainRef.current?.removeEventListener('wheel', onWheel)
                mainRef.current?.removeEventListener('touchstart', onTouchStart)
                mainRef.current?.removeEventListener('touchend', onTouchEnd)
            }
        }
    }, [])





    return (
        <>
            <div onClick={show} className={`main ${isHide ? 'main__hidden' : ''}`}>
                <Row align={'middle'} justify={'center'} style={{position: "relative"}}>
                    <Col>
                        <svg width="30" height="30" viewBox="0 0 400 400" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect width="400" height="400" rx="200" fill="#5390D9"/>
                            <path d="M223.555 290V184.444H176.889V290H223.555Z" fill="#56CFE1"/>
                            <path d="M134.667 290V110H88V290H134.667Z" fill="white"/>
                            <path d="M312.444 290V110H265.778V290H312.444Z" fill="white"/>
                        </svg>
                    </Col>
                    <Col>
                        <h1 className={`languages__title`}>
                            My Mobile
                        </h1>
                    </Col>
                    <Link to={`/change-language?default=true`}
                          className={`main__link ${theme.entity === 'dark' ? 'main__link--dark' : ""}`}>
                        {i18n.language}
                    </Link>
                </Row>
                <Operators isHide={isHide}/>
                <p className={`main__text ${isHide ? 'hide' : ''} ${theme.entity === 'dark' ? "main__text--dark" : ""}`}>
                    {t('moreForeignOperators', {value: '100'})}  <Link to={'/foreign-operators'}
                                            className={'main__operators'}>{t('foreignOperators')}</Link>
                </p>
                <ActionButtons isHide={isHide}/>
            </div>
            <div className={`divider ${theme.entity === 'dark' ? 'divider__dark' : ""}`}>
                <ShowToggler onToggle={onToggle} isHide={isHide}/>
            </div>
            <div className={`transactions__main ${isHide ? 'transactions__main--hide' : ''}`} ref={mainRef}>
                <Transactions isHide={isHide}/>
            </div>
        </>

    )
}

export default Index
