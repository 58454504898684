import React from 'react';

interface Props {
    isHide: boolean
    error?: string
}

const FormError: React.FC<Props> = ({
                                        error,
                                        isHide
                                    }) => {
    return (

                <p className={`form__error ${isHide ? 'form__error--small' : ''} ${error ? 'form__error--show' : ""}`}>
                    {error}
                </p>
    );
};

export default FormError;