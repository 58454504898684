import React from 'react';
import {useSelector} from "react-redux";
import {contactsSelector} from "../../../../../store/slices/contacts-slice";
import {useTranslation} from "react-i18next";
import {themeSelector} from "../../../../../store/slices/theme-slice";

interface props {
addContact: () => void
    payContact: () => void
    isHide: boolean
}

const Actions: React.FC<props> = ({
    addContact,
    payContact,
    isHide
                                  }) => {
    const {contact} = useSelector(contactsSelector)
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)
    return (
        <>
            {!contact.entity ? <button onClick={addContact}
                                       className={`action__btn ${isHide ? 'payment-contact__add--hide' : ''} action__btn--contacts payment-contact__add ${theme.entity === 'dark' ? "action__btn--dark" : ""}`}>
                {t('contacts.add')}
            </button> : null}
            <button onClick={payContact}
                    className={`language__continue ${isHide ? 'payment-contact__pay--hide' : ''} payment-contact__pay`}>
                {t('pay')}
            </button>
        </>
    );
};

export default Actions;