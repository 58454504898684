import {ITelegramUser, language} from "../interfaces/user/user";


export const user: ITelegramUser = window.Telegram.WebApp.initDataUnsafe.user



export const defaultUser = (lang?: language) => {
    const userDefault: any = {
        tg_id: user?.id,
        tg_firstname: user?.first_name,
        lang: 'new',
        status: 1
    }
    if (lang) {
        userDefault.lang = lang
    }
    if(user?.last_name) {
        userDefault['tg_lastname'] = user.last_name
    }

    if(user?.username) {
        userDefault['tg_username'] = user.username
    }
    return userDefault

}

