import React, {useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Transaction from "../transaction/transaction";
import NotFound from "../../../../../../shared/not-found/not-found";
import {getPaymentsApi} from "../../../../../../api/payments/payments";
import {extendPayments, setPaginationPayments, setPaymentError} from "../../../../../../store/slices/payment-slice";
import {message} from "antd";
import {useDispatch} from "react-redux";
import {IPagination, IPayment} from "../../../../../../interfaces/payment/payment";
import ListLoader from "../../../../../../shared/loader/list-loader";

interface Props {
    pagination: IPagination
    payments: IPayment[]
    isForm?: boolean
    isHide: boolean
}

const MemoizedList: React.FC<Props> = ({
                                           pagination,
                                           payments,
                                           isForm,
                                           isHide
                                       }) => {
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const dispatch = useDispatch()



    const fetchNextData = () => {
        setConfirmLoading(true)
        getPaymentsApi(pagination.page + 1)
            .then((response) => {
                if (response?.data) {
                    dispatch(extendPayments(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                }
            }).catch((e) => {
                if (e?.response?.data?.error?.body?.message) {
                    message.error(e.response.data.error.body.message)
                    dispatch(setPaymentError(e.response.data.error.body.message))

            }
        }).finally(() => {
            setConfirmLoading(false)
        })
    }



    return (
        <InfiniteScroll next={fetchNextData} style={{padding: '0'}}
                        className={'contact__scroll'}
                        hasMore={pagination.itemCount !== payments.length} loader={<>
        </>}
                        dataLength={payments.length}>
            {payments.length > 0 ? payments.map((payment) => (
                <Transaction key={payment.id} payment={payment}/>
            )) : <NotFound/>}
            {confirmLoading && <ListLoader/>}
        </InfiniteScroll>
    );
};

export default React.memo(MemoizedList);