import React from 'react'
import './operator.scss'
import {IOperator} from "../../../../interfaces/operators/operators";
import {Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {setIsSet, setPaymentFields} from "../../../../store/slices/payment-form-slice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {countriesSelector} from "../../../../store/slices/countries-slice";
import {ICountry} from "../../../../interfaces/countries/countries";
import {contactFieldsSelector, setFields, setFilled} from "../../../../store/slices/contact-form-slice";

interface Props {
    operator: IOperator
    isDrawer?: boolean
    onClose?: () => void
    isContact?: boolean
}

const Operator: React.FC<Props> = ({
                                       operator,
                                       isDrawer,
                                       onClose,
                                       isContact
                                   }) => {

    const {
        images,
        title,
        options
    } = operator
    const {theme} = useSelector(themeSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {countries} = useSelector(countriesSelector)
    const {i18n} = useTranslation()
    const {contactFields} = useSelector(contactFieldsSelector)

    const image = theme.entity === 'dark' ? images.dark : images.light

    const chooseOperator = () => {
       if (!isContact) {
           dispatch(setPaymentFields({phone: options.phoneNumber.prefix, operator: operator, icon: image}))
           dispatch(setIsSet(true))
       }
        if (isDrawer && onClose) {
           if (isContact) {
               dispatch(setFields({phone: options.phoneNumber.prefix, operator: operator, icon: image, name: contactFields.entity.name, id: undefined}))
               dispatch(setFilled(true))
           }
            onClose()
            return
        }
        navigate('/refill-balance')

    }

    const foundCountry = countries.entity.find(({id}) => options.countryID === id)



    return (
        <Row onClick={chooseOperator} justify={'space-between'}
             className={`transaction ${isDrawer ? 'transaction__no-margin' : ''}`} style={{cursor: 'pointer'}}>
            <div
                className={`transaction__icon operator__icon ${theme.entity === 'dark' ? 'transaction__icon--dark' : ""}`}>
                <img src={image} alt={title}/>
            </div>
            <div className={`transaction__info ${theme.entity === 'dark' ? 'transaction__info--dark' : ""}`}>
                <Row justify={'space-between'}>
                    <div className={'transaction__numbers'}>
                        <p className={`transaction__phone operator__name ${theme.entity === 'dark' ? 'transaction__phone--dark' : ""}`}>
                            {title}
                        </p>
                        <p className={`transaction__date operator__country ${theme.entity === 'dark' ? "transaction__date--dark" : ""} `}>
                            {foundCountry ? foundCountry[i18n.language as keyof ICountry] : null}
                        </p>
                    </div>
                </Row>
            </div>
        </Row>
    )
}

export default Operator
