import React from 'react'
import './payment-history.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {paymentsSelector,} from "../../../../store/slices/payment-slice";
import Loader from "../../../../shared/loader/loader";
import MemoizedPaymentScroll from "./components/memoizedPaymentScroll";

interface Props {
    isHide?: boolean

}

const PaymentHistory: React.FC<Props> = ({
                                             isHide
                                         }) => {
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)
    const {payments} = useSelector(paymentsSelector)

    if (!payments.loaded) return <Loader/>




    return (
        <div className={`transactions ${isHide ? 'transactions__show' : ''}`}>
            <h2
                className={`transactions__title ${theme.entity === 'dark' ? 'transactions__title--dark' : ""}`}>
                {t('transactionHistory')}
            </h2>
            <MemoizedPaymentScroll isHide={isHide} payments={payments.entity} pagination={payments.pagination}/>
        </div>
    )
}

export default PaymentHistory
