import React, {useEffect, useState} from 'react'
import './contacts.scss'
import {ContactsForm, ContactsInfo} from "./components/index";
import {backButtonClickedOff, backButtonClickedOn, onShowBackBtn} from "../../utils/telegram-events";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {contactFieldsSelector, removeFields} from "../../store/slices/contact-form-slice";
import {themeSelector} from "../../store/slices/theme-slice";
import ShowToggler from "../../shared/show-toggler/show-toggler";

interface Props {

}

const Contacts: React.FC<Props> = () => {
    const navigate = useNavigate()
    const {contactFields} = useSelector(contactFieldsSelector)
    const dispatch = useDispatch()
    const [isHide, setIsHide] = useState<boolean>(false)
    const setHide = () => setIsHide(true)
    const {theme} = useSelector(themeSelector)
    const setShow = () => setIsHide(false)

    const onToggle = () => setIsHide(!isHide)


    useEffect(() => {
        const onRedirect = () => {
            if (contactFields.isEdit) {
                dispatch(removeFields())
                return
            }
            navigate(-1)
        }

        backButtonClickedOn(onRedirect)
        onShowBackBtn()


        return () => {
            backButtonClickedOff(onRedirect)
        }
    }, [contactFields.isEdit])

    return (
        <div className={'contacts-wrapper'}>
            <ContactsForm  onShow={setShow} isHide={isHide}/>
            <div className={`divider divider__no-bottom-margin ${theme.entity === 'dark' ? 'divider__dark' : ""}`}>
                <ShowToggler isHide={isHide} onToggle={onToggle}/>
            </div>
            <ContactsInfo setHide={setHide} setShow={setShow} isHide={isHide}/>
        </div>
    )
}

export default Contacts
