import makeRequest from "../makeRequest";
import {IUser, language} from "../../interfaces/user/user";
import {user} from "../../constants/user";
import {AxiosResponse} from "axios";

export function createUser (data: IUser):Promise<AxiosResponse<{result: IUser}>> {
    return makeRequest.post(`/api/v1/users/`, data)
}


export function getUserApi ():Promise<AxiosResponse<{result: IUser}>>{
    return makeRequest.get(`/api/v1/users/${user?.id}`)
}

export function updateUser (lang: language):Promise<AxiosResponse<{result: IUser}>> {
    return makeRequest.patch(`/api/v1/users/${user?.id}`, {lang: lang})
}