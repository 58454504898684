import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {useTranslation} from "react-i18next";
import './transactions.scss'
import React, {useEffect} from "react";
import {
    paymentsSelector,
    resetPayments,
    setPaginationPayments,
    setPayment,
    setPaymentError
} from "../../../../store/slices/payment-slice";
import Loader from "../../../../shared/loader/loader";
import {getPaymentsApi} from "../../../../api/payments/payments";
import MemoizedList from "./components/memoized-list/memoized-list";
import {errorHandler} from "../../../../utils/error-handler";


interface Props {
    isHide: boolean
    isForm?: boolean
}

const Transactions: React.FC<Props> = ({
                                           isHide,
                                           isForm
                                       }) => {

    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {payments} = useSelector(paymentsSelector)

    useEffect(() => {
        const fetchData = (page?: number) => {
            getPaymentsApi(page ? page : payments.pagination.page)
                .then((response) => {
                    if (response?.data) {
                        dispatch(setPayment(response.data.result))
                        if (response?.data?.pagination) {
                            dispatch(setPaginationPayments(response.data.pagination))
                        }
                    }
                }).catch((e) => {
                    errorHandler(e, () => dispatch(setPaymentError(e.response.data.error.body.message)))
            })
        }
        fetchData(1)
        return () => {
            dispatch(resetPayments())
        }
    }, [dispatch])


    if (!payments.loaded) return <Loader/>

    return (
        <div className={`transactions ${isHide ? 'transactions__show' : ''}`}>
            <h2
                className={`transactions__title ${theme.entity === 'dark' ? 'transactions__title--dark' : ""}`}>
                {t('transactionHistory')}
            </h2>
            <MemoizedList isHide={isHide}  isForm={isForm} payments={payments.entity}
                          pagination={payments.pagination}/>
        </div>
    )
}

export default Transactions



