import {ReactElement} from "react";
import ChangeLanguage from "./pages/change-language/change-language";
import Index from "./pages/index";
import ForeignOperators from "./pages/foreign-operators/foreign-operators";
import RefillBalance from "./pages/refill-balance/refill-balance";
import Contacts from "./pages/contacts/contacts";
import PaymentDetail from "./pages/payment-detail/payment-detail";
import PaymentResult from "./pages/payment-result/payment-result";


interface IRoute {
    path: string
    component: ReactElement
}


export const routes: IRoute[] =
    [
        {
            component: <Index/>,
            path: '/'
        },
        {
            component: <PaymentDetail/>,
            path: '/payment/:id'
        },
        {
            component: <ChangeLanguage/>,
            path: '/change-language'
        },
        {
            component: <ForeignOperators/>,
            path: '/foreign-operators'
        },
        {
            component: <RefillBalance/>,
            path: '/refill-balance'
        },
        {
            component: <Contacts/>,
            path: '/contacts'
        },
        {
            component: <PaymentResult/>,
            path: '/payment-result'
        }
    ]