import React from 'react';
import {IPaymentUser} from "../../../../interfaces/payment/payment";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";

interface Props {
users_contact: IPaymentUser | null
    resetResultHandler: () => void
    onAddContact: () => void
}

const Actions: React.FC<Props> = ({
    users_contact,
    resetResultHandler,
    onAddContact
                                  }) => {
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)
    return (
        <div className={`payment-result__actions ${users_contact ? 'payment-result__actions--small' : ''}`}>
            <Link onClick={resetResultHandler} to={'/'}
                  className={users_contact ? `payment__result-btn action__btn action__btn--contacts payment-contact__add ${theme.entity === 'dark' ? "action__btn--dark" : ""}` : `language__continue action__btn--contacts payment-contact__pay ${theme.entity === 'dark' ? "action__btn--dark" : ""}`}>
                {t('goMain')}
            </Link>
            {!users_contact ? <button onClick={onAddContact}
                                      className={`language__continue payment-contact__pay payment-result__add`}>
                {t('contacts.add')}
            </button> : null}
        </div>
    );
};

export default Actions;