const utc = require('dayjs/plugin/utc')
const dayjs: any = require('dayjs')
dayjs.extend(utc)



export function formatDate(date: string, month: string) {
    let day = dayjs(date).utc().format("DD")
    let year = dayjs(date).utc().format("YYYY")
    let hours = dayjs(date).utc().format("HH:mm")
    return  `${day} ${month} ${year}, ${hours}`
}