import React, {useEffect} from 'react'
import './layouts.scss'
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setTheme, themeSelector} from "../../store/slices/theme-slice";
import {enableCloseConfirmation, offThemeEvent, onReady, onThemeEvent} from "../../utils/telegram-events";
import {getUserApi} from "../../api/users/users";
import {getUser, getUserError, userSelector} from "../../store/slices/user-slice";
import {useTranslation} from "react-i18next";
import Loader from "../../shared/loader/loader";
import {getOperatorsApi} from "../../api/operators/operators";
import {setOperators, setOperatorsError} from "../../store/slices/operator-slice";
import {errorHandler} from "../../utils/error-handler";


interface Props {

}

const Layout: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const {pathname} = useLocation()
    const {i18n} = useTranslation()
    const navigate = useNavigate()
    const {user} = useSelector(userSelector)


    useEffect(() => {

        getUserApi().then((response) => {
            if (response?.data) {
                dispatch(getUser(response.data.result))
                i18n.changeLanguage(response.data.result?.lang)
            }
        }).catch((e) => {
            navigate('/change-language')
            dispatch(getUserError(''))
        })


        enableCloseConfirmation()
        const onChangeTheme = () => {
            dispatch(setTheme(window.Telegram.WebApp.colorScheme))
        }
        onThemeEvent(onChangeTheme)
        onReady()
        return () => {
            offThemeEvent(onChangeTheme)
        }
    }, [dispatch])

    const {theme} = useSelector(themeSelector)

    useEffect(() => {
        if (pathname === '/change-language') {
            document.body.style.height = 'var(--tg-viewport-stable-height)'
        } else {
            document.body.style.height = 'auto'
        }
        const getOperators = () => {
            getOperatorsApi()
                .then((response) => {
                    if (response?.data) {
                        dispatch(setOperators(response.data.result))
                    }
                }).catch((e) => {
                    errorHandler(e, () => dispatch(setOperatorsError(e.response?.data?.error?.body?.message)))
            })
        }

        getOperators()
    }, [pathname])


    if (!user.loaded) {
        return <Loader/>
    }

    return (
        <div
            className={`layout ${pathname === '/change-language' ? '' : 'layout__fixed'} ${theme.entity === 'dark' ? 'layout__dark' : ""}`}>
            <Outlet/>
        </div>
    )
}

export default Layout
