import React from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {contactFieldsSelector} from "../../../../../store/slices/contact-form-slice";

interface Props {
errors: any
    confirmLoading: boolean
    isHide: boolean
    phone: string | undefined
    name: string | undefined
}

const SubmitBtn: React.FC<Props> = ({
    errors,
    confirmLoading,
    isHide,
    phone,
    name
                                    }) => {
    const {t} =useTranslation()
    const {contactFields} = useSelector(contactFieldsSelector)
    return (
        <button disabled={confirmLoading} type={"submit"}
                className={`language__continue refill__btn ${isHide ? 'refill__btn--hide' : ''} ${phone && name ? "" : "language__continue--disabled"}  ${errors?.name || errors?.phone_number ? 'language__continue--disabled' : ''}`}>
            {t(contactFields.isFilled ? 'contacts.edit' : 'contacts.add')}
        </button>
    );
};

export default SubmitBtn;