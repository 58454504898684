export interface IUserColor {
    backgroundColor: string
    color: string
}

export const userColors: IUserColor[] = [
    {
        backgroundColor: '#E7F4FF',
        color: "#51A2E5"
    },
    {
        backgroundColor: '#D5FFC1',
        color: "#759F62"
    },
    {
        backgroundColor: '#FBE4FF',
        color: '#BE63CA'
    },
    {
        backgroundColor: '#FBECA3',
        color: "#DC9A0E"
    },
    {
        backgroundColor: '#FFD1D3',
        color: '#D9363B'
    },
    {
        backgroundColor: '#E9E4FF',
        color: "#7A5BFF"
    }
]
