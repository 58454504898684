import React, {useEffect, useState} from 'react'
import './refill-form.scss'
import {useForm} from "react-hook-form";
import OperatorsDrawer from "./components/operators-drawer/operators-drawer";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {paymentFieldSelector, removePaymentFields, setResult} from "../../../../store/slices/payment-form-slice";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {spacesRegEx} from "../../../../constants/regex";
import {InvoiceStatus, IPayment, IPaymentForm, IPaymentRequest} from "../../../../interfaces/payment/payment";
import {user} from "../../../../constants/user";
import {createPaymentsApi, getPaymentApi, getPaymentsApi} from "../../../../api/payments/payments";
import {setPaginationPayments, setPayment, setPaymentError} from "../../../../store/slices/payment-slice";
import {message} from "antd";
import Loader from "../../../../shared/loader/loader";
import {openInvoice} from "../../../../utils/telegram-events";
import {AxiosResponse} from "axios";
import {useNavigate} from "react-router-dom";
import PhoneInput from "./components/phone-input/phone-input";
import SumInput from "./components/sum-input/sum-input";
import LazyLoadImageComponent from "../../../../shared/lazy-load-image/lazy-load-image";
import {operatorsSelector} from "../../../../store/slices/operator-slice";
import FormError from "../../../../shared/form-error/form-error";
import SubmitBtn from "./components/submit-btn/submit-btn";
import {findInputImage} from "../../../../utils/findImage";
import {errorHandler} from "../../../../utils/error-handler";


interface Props {
    isHide: boolean
    onShow: () => void
}


const RefillForm: React.FC<Props> = ({
                                         isHide,
                                         onShow
                                     }) => {
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)
    const {operators} = useSelector(operatorsSelector)
    const {paymentFields} = useSelector(paymentFieldSelector)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        setValue,
        setError,
        watch,
        clearErrors,
        setFocus
    } = useForm<IPaymentForm>();

    const phone = watch('phone_number')
    const amount = watch('amount')


    const getPaymentsHandler = () => {
        getPaymentsApi(1)
            .then((response) => {
                if (response?.data) {
                    dispatch(setPayment(response.data.result))
                    if (response.data?.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                    dispatch(removePaymentFields())
                    navigate('/payment-result')
                    message.success(t('payment.added'))
                    reset()
                }
            }).catch((e) => {
            errorHandler(e, () => dispatch(setPaymentError(e.response?.data?.error?.body?.message)))
        })
    }



    const invoiceHandlerFunc = (status: InvoiceStatus) => {
        switch (status) {
            case "paid":
                getPaymentsHandler()
                break
            case "failed":
                message.error(t('payment.error'))
                break
            case "cancelled":
                message.warning(t('payment.canceled'))
                break
        }
    }


    const onFinish = (values: any) => {
        const slicedCode = values.phone_number.trim().slice(4, 6)
        const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode))
        if (values.phone_number.length < 6) {
            setError('phone_number', {
                type: 'manual',
                message: `${t('payment.incorrect')}`
            })
            return
        }
        if (paymentFields.entity.operator && paymentFields.isSet) {
            const regExp = new RegExp(paymentFields.entity.operator.options.phoneNumber.validation)
            if (!regExp.test(values.phone_number)) {
                setError('phone_number', {
                    type: 'manual',
                    message: `${t('payment.incorrect')}`
                })
                return
            }
        }
        if (foundOperator && !paymentFields.isSet) {
            const regExp = new RegExp(foundOperator.options.phoneNumber.validation)
            if (!regExp.test(values.phone_number)) {
                setError('phone_number', {
                    type: 'manual',
                    message: `${t('payment.incorrect')}`
                })
                return
            }
        }

        const value = Number(values.amount.replace(spacesRegEx, ''))
        const operator = paymentFields.entity.operator
        let min = operator ? operator.options.amount.min : 500
        let max = operator ? operator.options.amount.max : 1500000
        if (value && value < min || value && value > max) {
            setError('amount', {
                type: 'manual',
                message: `${t('errors.sum', {
                    min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                    max: new Intl.NumberFormat('ru-RU').format(max).toString()
                })}`
            })
            return
        }

        const body: IPaymentRequest = {
            ...values,
            phone_number: values.phone_number.replace(spacesRegEx, ''),
            amount: values.amount.replace(spacesRegEx, ''),
            status: 1,
            tg_id: user.id.toString(),
        }

        if (paymentFields.entity.operator) {
            body.operator_id = paymentFields.entity.operator.id
        }
        setConfirmLoading(true)
        createPaymentsApi(body)
            .then((response: AxiosResponse<{ result: IPayment }>) => {
                if (response?.data) {
                    dispatch(setResult(response.data.result))
                    openInvoice(response.data.result.invoice_link, invoiceHandlerFunc)
                    setTimeout(() => {
                        setConfirmLoading(false)
                    }, 3000)
                }
            }).catch((e) => {
                errorHandler(e, () => setConfirmLoading(false))
        })

    }


    useEffect(() => {
        if (paymentFields.isFilled) {
            setValue('phone_number', paymentFields.entity.phone)
        } else {
            reset()
            dispatch(removePaymentFields())
        }
    }, [paymentFields.entity.phone])


    const onSumFocus = () => {
        setFocus('amount')
        if (!amount || amount === '0') {
            setValue('amount', '')
        }
    }


    return (
        <form onClick={onShow} onSubmit={handleSubmit(onFinish)} className={`form ${isHide ? 'form__hide' : ''}`}>
            {confirmLoading && <Loader/>}
            <div>
                <div className={`phone__wrapper ${isHide ? 'phone__wrapper--hide' : ''}`}>
                    <div
                        className={`phone__icon ${paymentFields.entity.operator ? '' : 'phone__icon--default'} ${isHide ? 'phone__icon--small' : ''} ${theme.entity === 'dark' ? 'phone__icon--dark' : ''}`}>
                        <LazyLoadImageComponent
                            alt={''}
                            src={findInputImage(paymentFields.entity.operator, theme.entity)}
                        />
                    </div>
                    <div
                        className={`phone__container ${theme.entity === 'dark' ? 'phone__container--dark' : ''} ${isHide ? 'phone__container--small' : ''} ${errors?.phone_number ? "error__border" : ""}`}>
                        <OperatorsDrawer isHide={isHide}/>
                        <PhoneInput clearErrors={clearErrors} setError={setError} errors={errors} setValue={setValue}
                                    isHide={isHide}/>
                    </div>
                </div>
                <FormError isHide={isHide} error={errors?.phone_number?.message}/>
            </div>
            <div>
                <div onClick={onSumFocus}
                     className={`sum ${theme.entity === 'dark' ? 'sum__dark' : ''} ${isHide ? 'sum__hide' : ''} ${errors?.amount ? "error__border" : ""}`}>
                    <SumInput clearErrors={clearErrors}
                              setError={setError}
                              errors={errors}
                              setValue={setValue}
                              isHide={isHide}
                              register={register}
                              amount={amount}/>
                    <p className={`sum__currency ${isHide ? 'sum__currency--small' : ''} ${errors?.amount ? "error__color" : ""}`}>
                        {t('sum').toLowerCase()}
                    </p>
                </div>
                <FormError isHide={isHide} error={errors?.amount?.message}/>
            </div>
           <SubmitBtn confirmLoading={confirmLoading} isHide={isHide} phone={phone} amount={amount} errors={errors}/>
        </form>
    )
}


export default RefillForm
