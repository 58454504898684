import React, {useEffect} from 'react'
import './contacts-history.scss'
import ContactsHistoryItem from "./components/contacts-history-item/contacts-history-item";
import {getPaymentsApi} from "../../../../api/payments/payments";
import {
    paymentsSelector,
    setPaginationPayments,
    setPayment,
    setPaymentError
} from "../../../../store/slices/payment-slice";
import {message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import NotFound from "../../../../shared/not-found/not-found";
import Loader from "../../../../shared/loader/loader";


interface Props {

}

const ContactsHistory: React.FC<Props> = () => {
    const dispatch = useDispatch()

    const {payments} = useSelector(paymentsSelector)

    useEffect(() => {
        getPaymentsApi(payments.pagination.page)
            .then((response) => {
                if (response?.data) {
                    dispatch(setPayment(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                }
            }).catch((e) => {
                if (e?.response?.data?.error?.body?.message) {
                    message.error(e.response.data.error.body.message)
                    dispatch(setPaymentError(e.response.data.error.body.message))
                }
        })
    }, [])


    if (!payments.loaded) return <Loader/>



    return (
        <div className={'contacts'}>
            {payments.entity.length > 0 ? payments.entity.map((payment, index) => (
                <ContactsHistoryItem payment={payment} index={index} key={payment.id}/>
            )) : <NotFound/>}
        </div>
    )
}

export default ContactsHistory
