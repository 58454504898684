import React from 'react';
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../../store/slices/theme-slice";
import {useTranslation} from "react-i18next";
import {ContactField, IContactFormFields} from "../../../../../interfaces/contacts/contacts";
import {UseFormRegister} from "react-hook-form";

interface Props {
    errors: any
    setValue: (name: ContactField, value: string) => void
    register: UseFormRegister<IContactFormFields>
    isHide: boolean
}

const NameInput: React.FC<Props> = ({
                                        setValue,
                                        isHide,
                                        register,
                                        errors
                                    }) => {

    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()

    return (
        <div
            className={`sum ${theme.entity === 'dark' ? 'sum__dark' : ''} ${isHide ? 'sum__hide' : ''} ${errors?.name ? "error__border" : ""}`}>
            <input placeholder={`${t('name')}`} type={'text'} {...register('name', {
                required: {
                    value: true,
                    message: t('required')
                },

                onBlur: (e => setValue('name', e.target.value.trim())),
            })}
                   className={`name__input ${isHide ? 'phone__input--small' : ''} ${errors?.name ? "error__color" : ""} ${theme.entity === 'dark' ? 'phone__input--dark' : ""}`}
            />
        </div>
    );
};

export default NameInput;