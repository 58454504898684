import React from 'react';
import './show-toggler.scss'
import {useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";

interface Props {
    isHide: boolean
    onToggle: () => void
}

const ShowToggler: React.FC<Props> = ({
                                          isHide,
                                          onToggle
                                      }) => {
    const {theme} = useSelector(themeSelector)
    return (
        <div onClick={onToggle} className={`toggler ${theme.entity === 'dark' ? 'toggler__dark' : ''}`}>
            <svg className={`toggler__icon ${isHide ? 'toggler__icon--reverse' : ''}`} width="14" height="16"
                 viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6.75L7 1.75L2 6.75M12 14.25L7 9.25L2 14.25" stroke="#50A7EA" strokeWidth="2.4"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};

export default ShowToggler;