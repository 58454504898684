import React from 'react';
import {userColors} from "../../../../../constants/user-colors";
import {useSelector} from "react-redux";
import {contactsSelector} from "../../../../../store/slices/contacts-slice";

interface Props {
    img: string | undefined
    isHide: boolean
}

const Icon: React.FC<Props> = ({
    img,
    isHide
                               }) => {
    const {contact} = useSelector(contactsSelector)

    return (
        <div
            className={`payment-contact__icon ${isHide ? 'payment-contact__icon--hide' : ''}  payment-contact__icon--full`}
            style={contact.entity ? {
                background: contact.entity.image,
                color: userColors.find(({backgroundColor}) => backgroundColor === contact.entity?.image)?.color,
                borderRadius: '50%',
                borderColor: "transparent"
            } : undefined}>
            {contact.entity ? contact.entity.name[0].toUpperCase() : <img src={img} alt={''}/>}
        </div>
    );
};

export default Icon;