import React from 'react'
import darkNotFound from "../../assets/nothing-dark.svg";
import lightNotFound from "../../assets/nothing-light.svg";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";

interface Props {

}

const NotFound: React.FC<Props> = () => {
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)

    return (
        <div className={'transactions__not-found'}>
            <img src={theme.entity === 'dark' ? darkNotFound : lightNotFound} alt={""}/>
            <p className={`transactions__text ${theme.entity === 'dark' ? "transactions__text--dark" : ''}`}>
                {t('notFound')}
            </p>
        </div>
    )
}

export default NotFound
