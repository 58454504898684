import React from 'react'
import './action-buttons.scss'
import {Row} from "antd";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";


interface Props {
    isHide: boolean
}

const ActionButtons: React.FC<Props> = ({
                                            isHide
                                        }) => {
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)

    return (
        <Row align={'middle'} justify={'space-between'}
             className={`action__wrapper ${isHide ? 'action__wrapper--hide' : ''}`}>
            <Link to={'/refill-balance'} className={'action__btn action__btn--balance'}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0.5C4.49 0.5 0 4.99 0 10.5C0 16.01 4.49 20.5 10 20.5C15.51 20.5 20 16.01 20 10.5C20 4.99 15.51 0.5 10 0.5ZM14 11.25H10.75V14.5C10.75 14.91 10.41 15.25 10 15.25C9.59 15.25 9.25 14.91 9.25 14.5V11.25H6C5.59 11.25 5.25 10.91 5.25 10.5C5.25 10.09 5.59 9.75 6 9.75H9.25V6.5C9.25 6.09 9.59 5.75 10 5.75C10.41 5.75 10.75 6.09 10.75 6.5V9.75H14C14.41 9.75 14.75 10.09 14.75 10.5C14.75 10.91 14.41 11.25 14 11.25Z"
                        fill="white"/>
                </svg>

                <p>
                    {t('pay')}
                </p>
            </Link>
            <Link to={'/contacts'}
                  className={`action__btn action__btn--contacts ${theme.entity === 'dark' ? "action__btn--dark" : ""}`}>
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={'action__icon'}
                          d="M17.51 4.35L11.57 0.92C10.6 0.36 9.40004 0.36 8.42004 0.92L2.49004 4.35C1.52004 4.91 0.920044 5.95 0.920044 7.08V13.92C0.920044 15.04 1.52004 16.08 2.49004 16.65L8.43004 20.08C9.40004 20.64 10.6 20.64 11.58 20.08L17.52 16.65C18.49 16.09 19.09 15.05 19.09 13.92V7.08C19.08 5.95 18.48 4.92 17.51 4.35ZM10 5.84C11.29 5.84 12.33 6.88 12.33 8.17C12.33 9.46 11.29 10.5 10 10.5C8.71004 10.5 7.67004 9.46 7.67004 8.17C7.67004 6.89 8.71004 5.84 10 5.84ZM12.68 15.16H7.32004C6.51004 15.16 6.04004 14.26 6.49004 13.59C7.17004 12.58 8.49004 11.9 10 11.9C11.51 11.9 12.83 12.58 13.51 13.59C13.96 14.25 13.48 15.16 12.68 15.16Z"
                          fill="#50A7EA"/>
                </svg>

                <p>
                    {t('contacts.title')}
                </p>
            </Link>
        </Row>
    )
}

export default ActionButtons
