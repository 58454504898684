import React from 'react';
import {PatternFormat} from "react-number-format";
import {spacesRegEx} from "../../../../../../constants/regex";
import {ContactField, IContactForm} from "../../../../../../interfaces/contacts/contacts";
import {contactFieldsSelector, resetOperator, setFields} from "../../../../../../store/slices/contact-form-slice";
import {useDispatch, useSelector} from "react-redux";
import {operatorsSelector} from "../../../../../../store/slices/operator-slice";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {useTranslation} from "react-i18next";
import {findInputImage} from "../../../../../../utils/findImage";

interface Props {
    clearErrors: (error: ContactField) => void
    setError: (type: ContactField, options: any) => void
    errors: any
    setValue: (name: ContactField, value: string) => void
    isHide: boolean
    name: string | undefined
}

const PhoneInput: React.FC<Props> = ({
                                         clearErrors,
                                         setError,
                                         errors,
                                         setValue,
                                         isHide,
                                         name
                                     }) => {
    const dispatch = useDispatch()
    const {contactFields} = useSelector(contactFieldsSelector)
    const {operators} = useSelector(operatorsSelector)
    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()
    return (
        <PatternFormat
            allowEmptyFormatting
            format={contactFields.entity.operator ? contactFields.entity.operator.options.phoneNumber.mask : '+### ## ### ## ##'}
            mask={' '}
            prefix={'+'}
            value={contactFields.entity.phone}
            onValueChange={(values, sourceInfo) => {
                clearErrors('phone_number')
                const prefix = values.value.slice(0,3)

                const slicedCode = values.value.slice(3, 5).trim().replace(spacesRegEx, '')
                const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode) && item.options.phoneNumber.prefix.replace('+', '') === prefix)
                const fields: IContactForm = {
                    id: contactFields.entity.id,
                    phone: `+${values.value}`,
                    icon: findInputImage(contactFields.entity.operator, theme.entity),
                    operator: foundOperator,
                    name: name
                }
                if (values.value.length < 3) {
                    dispatch(resetOperator())
                }
                if (slicedCode.length > 1 && !foundOperator && !contactFields.isFilled) {
                    setError('phone_number', {
                        type: 'manual',
                        message: `${t('payment.operatorNotFound')}`
                    })
                    dispatch(resetOperator())
                }
                if (foundOperator && !contactFields.isFilled) {
                    dispatch(setFields(fields))
                }
                setValue('phone_number', `+${values.value}`)
            }}
            onBlur={(e: any) => {
                const prefix = e.target.value.slice(0,4)
                const value = e.target.value.replace(spacesRegEx, '')
                const slicedCode = value.slice(4, 6).trim().replace(spacesRegEx, '')
                const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode) && item.options.phoneNumber.prefix === prefix)
                const fields: IContactForm = {
                    id: contactFields.entity.id,
                    phone: value,
                    icon: findInputImage(contactFields.entity.operator, theme.entity),
                    operator: foundOperator,
                    name: name
                }
                if (value.length < 6) {
                    setError('phone_number', {
                        type: 'manual',
                        message: `${t('payment.incorrect')}`
                    })
                }
                if (contactFields.entity.operator && contactFields.isFilled) {
                    const regExp = new RegExp(contactFields.entity.operator.options.phoneNumber.validation)
                    if (regExp.test(value)) {
                        clearErrors('phone_number')
                    } else {
                        setError('phone_number', {
                            type: 'manual',
                            message: `${t('payment.incorrect')}`
                        })
                    }
                }
                if (foundOperator && !contactFields.isFilled) {
                    const regExp = new RegExp(foundOperator.options.phoneNumber.validation)
                    if (regExp.test(value)) {
                        clearErrors('phone_number')
                    } else {
                        setError('phone_number', {
                            type: 'manual',
                            message: `${t('payment.incorrect')}`
                        })
                    }
                    dispatch(setFields(fields))
                }
            }}
            className={`phone__input ${isHide ? 'phone__input--small' : ''} ${errors?.phone_number ? "error__color" : ""} ${theme.entity === 'dark' ? 'phone__input--dark' : ""}`}
        />
    );
};

export default PhoneInput;