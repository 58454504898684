import {IUser, IUserState} from "../../interfaces/user/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: IUserState = {
    user: {
        entity: undefined,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}


export const userSelector = (state: { user: IUserState }) => state.user




const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUser: (state, action: PayloadAction<IUser>) => {
            state.user.entity = action.payload
            state.user.loaded = true
            state.user.errors.isError = false
        },
        getUserError: (state, action: PayloadAction<string>) => {
            state.user.loaded = true
            state.user.errors.isError = false
            state.user.errors.message = action.payload
        },
        setUser: (state, action: PayloadAction<IUser>) => {
            state.user.entity = action.payload
            state.user.loaded = true
        }
    }
})


export const {
    getUser,
    getUserError,
    setUser
} = userSlice.actions


export default userSlice.reducer