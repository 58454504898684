import React, {useEffect, useState} from 'react'
import './contacts-form.scss'
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import OperatorsDrawer
    from "../../../refill-balance/components/refill-form/components/operators-drawer/operators-drawer";
import {useDispatch, useSelector} from "react-redux";
import {contactFieldsSelector, removeFields,} from "../../../../store/slices/contact-form-slice";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {createContact, editContactApi, getContactsApi} from "../../../../api/contacts/contacts";
import {user} from "../../../../constants/user";
import {IContactFormFields, IContactItem} from "../../../../interfaces/contacts/contacts";
import Loader from "../../../../shared/loader/loader";
import {message} from "antd";
import {
    addContact,
    editContact,
    setContacts,
    setContactsError,
    setPaginationContacts
} from "../../../../store/slices/contacts-slice";
import {spacesRegEx} from "../../../../constants/regex";
import {getPaymentsApi} from "../../../../api/payments/payments";
import {setPaginationPayments, setPayment, setPaymentError} from "../../../../store/slices/payment-slice";
import {userColors} from "../../../../constants/user-colors";
import {randomizer} from "../../../../utils/randomizer";
import PhoneInput from "./components/phone-input/phone-input";
import LazyLoadImageComponent from "../../../../shared/lazy-load-image/lazy-load-image";
import {operatorsSelector} from "../../../../store/slices/operator-slice";
import FormError from "../../../../shared/form-error/form-error";
import {findInputImage} from "../../../../utils/findImage";
import NameInput from "./components/name-input";
import SubmitBtn from "./components/submit-btn";
import {errorHandler} from "../../../../utils/error-handler";

interface Props {
    isHide: boolean
    onShow: () => void
}

const ContactsForm: React.FC<Props> = (
    {
        isHide,
        onShow
    }
) => {
    const dispatch = useDispatch()

    const {contactFields} = useSelector(contactFieldsSelector)
    const {operators} = useSelector(operatorsSelector)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
        setValue,
        clearErrors,
        watch,
        setError
    } = useForm<IContactFormFields>({mode: 'onChange'});


    const name = watch('name')
    const phone = watch('phone_number')


    const fetchContacts = () => {
        getContactsApi('1')
            .then((response) => {
                if (response?.data) {
                    dispatch(setContacts(response.data.result))
                    if (response.data?.pagination) {
                        dispatch(setPaginationContacts(response.data.pagination))
                    }
                }
            }).catch((e) => {
            errorHandler(e, () => dispatch(setContactsError(e.response.data.error.body.message)))

        })
    }

    const fetchHistory = () => {
        getPaymentsApi(1)
            .then((response) => {
                if (response?.data) {
                    dispatch(setPayment(response.data.result))
                    if (response.data?.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                }
            }).catch((e) => {
            errorHandler(e, () => dispatch(setPaymentError(e.response.data.error.body.message)))
        })
    }


    useEffect(() => {
            if (contactFields.isEdit) {
                setValue('name', contactFields.entity.name)
                setValue('phone_number', contactFields.entity.phone)
                if (contactFields.entity.name) {
                    clearErrors('name')
                }
            } else {
                reset()
                clearErrors()
                dispatch(removeFields())
            }

        }, [contactFields.isEdit, contactFields.entity.name, contactFields.entity.phone]
    )


    const onFinish = (values: any) => {
        const slicedCode = values.phone_number.trim().slice(4, 6)
        const foundOperator = operators.entity.find((item) => item.options.phoneNumber.codes.split('|').includes(slicedCode))
        if (values.phone_number.length < 6) {
            setError('phone_number', {
                type: 'manual',
                message: `${t('payment.incorrect')}`
            })
            return
        }
        if (contactFields.entity.operator && contactFields.isFilled) {
            const regExp = new RegExp(contactFields.entity.operator.options.phoneNumber.validation)
            if (!regExp.test(values.phone_number)) {
                setError('phone_number', {
                    type: 'manual',
                    message: `${t('payment.incorrect')}`
                })
                return
            }
        }
        if (foundOperator && !contactFields.isFilled) {
            const regExp = new RegExp(foundOperator.options.phoneNumber.validation)
            if (!regExp.test(values.phone_number)) {
                clearErrors('phone_number')

                setError('phone_number', {
                    type: 'manual',
                    message: `${t('payment.incorrect')}`
                })
                return
            }
        }
        setConfirmLoading(true)
        if (contactFields.isEdit && contactFields.entity.id) {
            const editData = {
                name: values.name,
                phone_number: values.phone_number.replace(spacesRegEx, ''),
                id: contactFields.entity.id,
                tg_id: user.id.toString(),
                status: 1
            }
            editContactApi(editData, contactFields.entity.id).then((response) => {
                if (response?.data) {
                    dispatch(editContact(response.data.result))
                    message.success(t('contacts.edited'))
                    dispatch(removeFields())
                    reset()
                    fetchContacts()
                    fetchHistory()
                }
            }).catch((e) => {
                errorHandler(e)

            }).finally(() => setConfirmLoading(false))
            return
        }
        const data: IContactItem = {
            ...values,
            phone_number: values.phone_number.replace(spacesRegEx, ''),
            tg_id: user?.id,
            status: 1,
            image: userColors[randomizer(0, 5)].backgroundColor
        }
        createContact(data)
            .then((response) => {
                if (response?.data) {
                    dispatch(addContact(response.data.result))
                    message.success(t('contacts.created'))
                    dispatch(removeFields())
                    reset()
                    fetchContacts()
                    fetchHistory()
                }
            }).catch((e) => {
            errorHandler(e)

        }).finally(() => setConfirmLoading(false))

    }


    return (
        <form onClick={onShow} onSubmit={handleSubmit(onFinish)} className={`form ${isHide ? 'form__hide' : ''}`}>
            {confirmLoading && <Loader/>}
            <div>

                <div className={`phone__wrapper  ${isHide ? 'phone__wrapper--hide' : ''}`}>
                    <div
                        className={`phone__icon ${isHide ? 'phone__icon--small' : ''} ${theme.entity === 'dark' ? 'phone__icon--dark' : ''}`}>
                        <LazyLoadImageComponent
                            alt={''}
                            src={findInputImage(contactFields.entity.operator, theme.entity)}
                        />
                    </div>
                    <div
                        className={`phone__container ${theme.entity === 'dark' ? 'phone__container--dark' : ''} ${isHide ? 'phone__container--small' : ''} ${errors?.phone_number ? "error__border" : ""}`}>
                        <OperatorsDrawer isContact={true} isHide={isHide}/>
                        <PhoneInput
                            clearErrors={clearErrors}
                            setError={setError}
                            errors={errors}
                            setValue={setValue}
                            isHide={isHide}
                            name={name}
                        />

                    </div>
                </div>
                <FormError isHide={isHide} error={errors?.phone_number?.message}/>
            </div>

            <div>
                <NameInput errors={errors} setValue={setValue} isHide={isHide} register={register}/>
                <FormError isHide={isHide} error={errors?.name?.message}/>
            </div>

            <SubmitBtn phone={phone} name={name} errors={errors} confirmLoading={confirmLoading} isHide={isHide}/>

        </form>

    )
}

export default ContactsForm
