import React, {useState} from 'react';
import PaymentItem from "./payment-item/payment-item";
import NotFound from "../../../../../shared/not-found/not-found";
import ListLoader from "../../../../../shared/loader/list-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {getPaymentsApi} from "../../../../../api/payments/payments";
import {extendPayments, setPaginationPayments, setPaymentError} from "../../../../../store/slices/payment-slice";
import {message} from "antd";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {IPagination, IPayment} from "../../../../../interfaces/payment/payment";

interface Props {
    pagination: IPagination
    payments: IPayment[]
    isHide?: boolean
}

const MemoizedPaymentScroll: React.FC<Props> = ({
                                                    payments,
                                                    pagination,
                                                    isHide,
                                                }) => {

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const {id} = useParams()

    const fetchHistory = () => {
        if (id) {
            setConfirmLoading(true)
            getPaymentsApi(pagination.page + 1, id)
                .then((response) => {
                    if (response?.data) {
                        dispatch(extendPayments(response.data.result))
                        if (response.data.pagination) {
                            dispatch(setPaginationPayments(response.data.pagination))
                        }
                    }
                }).catch((e) => {
                if (e?.response?.data?.error?.body?.message) {
                    message.error(e.response.data.error.body.message)
                    dispatch(setPaymentError(e.response.data.error.body.message))
                }
            }).finally(() => setConfirmLoading(false))
        }
    }


    return (
        <InfiniteScroll next={fetchHistory} style={{padding: '0'}} className={'contact__scroll'}
                        hasMore={pagination.itemCount !== payments.length} loader={<>
        </>}
                        dataLength={payments.length}>
            {payments.length > 0 ? payments.map((payment) => (
                <PaymentItem payment={payment} key={payment.id}/>
            )) : <NotFound/>}
            {confirmLoading && <ListLoader/>}
        </InfiniteScroll>
    );
};

export default React.memo(MemoizedPaymentScroll);