import React, {useState} from 'react'
import {Col, ConfigProvider, message, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";
import './contact-remove.scss'
import {IContactGet} from "../../../../../../../../interfaces/contacts/contacts";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../../../../../../../store/slices/theme-slice";
import {getContactsApi, removeContactApi} from "../../../../../../../../api/contacts/contacts";
import {
    setContacts,
    setContactsError,
    setPaginationContacts
} from "../../../../../../../../store/slices/contacts-slice";
import Loader from "../../../../../../../../shared/loader/loader";
import {getPaymentsApi} from "../../../../../../../../api/payments/payments";
import {setPaginationPayments, setPayment, setPaymentError} from "../../../../../../../../store/slices/payment-slice";
import {errorHandler} from "../../../../../../../../utils/error-handler";

interface Props {
    contact: IContactGet
}

const ContactRemove: React.FC<Props> = (
    {
        contact
    }
) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const {name} = contact
    const [isShow, setIsShow] = useState<boolean>(false)
    const {theme} = useSelector(themeSelector)

    const fetchContacts = () => {
        getContactsApi('1')
            .then((response) => {
                if (response?.data) {
                    dispatch(setContacts(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationContacts(response.data.pagination))
                    }
                }
            }).catch((e) => {
            errorHandler(e, () => dispatch(setContactsError(e.response.data.error.body.message)))
        })
    }

    const fetchHistory = () => {
        getPaymentsApi(1)
            .then((response) => {
                if (response?.data) {
                    dispatch(setPayment(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                }
            }).catch((e) => {
            errorHandler(e, () => dispatch(setPaymentError(e.response.data.error.body.message)))
        })
    }
    const onShow = () => {
        setIsShow(true)
    }
    const onHide = () => {
        setIsShow(false)
    }

    const onRemove = () => {
        setConfirmLoading(true)
        removeContactApi(contact.id)
            .then((response) => {
                if (response?.data) {
                    message.success(t('contacts.deleted'))
                    fetchContacts()
                    fetchHistory()
                    onHide()
                }
            }).catch((e) => {
            errorHandler(e)
        }).finally(() => setConfirmLoading(false))
    }

    return (
        <>
            {confirmLoading && <Loader/>}
            <svg onClick={onShow} style={{cursor: 'pointer'}} width="20" height="21" viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.5584 4.85829C16.2167 4.72496 14.875 4.62496 13.525 4.54996V4.54163L13.3417 3.45829C13.2167 2.69163 13.0334 1.54163 11.0834 1.54163H8.90005C6.95838 1.54163 6.77505 2.64163 6.64172 3.44996L6.46672 4.51663C5.69172 4.56663 4.91672 4.61663 4.14172 4.69163L2.44172 4.85829C2.09172 4.89163 1.84172 5.19996 1.87505 5.54163C1.90838 5.88329 2.20838 6.13329 2.55838 6.09996L4.25838 5.93329C8.62505 5.49996 13.0251 5.66663 17.4417 6.10829C17.4667 6.10829 17.4834 6.10829 17.5084 6.10829C17.8251 6.10829 18.1 5.86663 18.1334 5.54163C18.1584 5.19996 17.9084 4.89163 17.5584 4.85829Z"
                    fill="#748191" fillOpacity="0.6"/>
                <path
                    d="M16.0254 7.28337C15.8254 7.07504 15.5504 6.95837 15.2671 6.95837H4.73378C4.45044 6.95837 4.16711 7.07504 3.97544 7.28337C3.78378 7.49171 3.67544 7.77504 3.69211 8.06671L4.20878 16.6167C4.30044 17.8834 4.41711 19.4667 7.32544 19.4667H12.6754C15.5838 19.4667 15.7004 17.8917 15.7921 16.6167L16.3088 8.07504C16.3254 7.77504 16.2171 7.49171 16.0254 7.28337ZM11.3838 15.2917H8.60878C8.26711 15.2917 7.98378 15.0084 7.98378 14.6667C7.98378 14.325 8.26711 14.0417 8.60878 14.0417H11.3838C11.7254 14.0417 12.0088 14.325 12.0088 14.6667C12.0088 15.0084 11.7254 15.2917 11.3838 15.2917ZM12.0838 11.9584H7.91711C7.57544 11.9584 7.29211 11.675 7.29211 11.3334C7.29211 10.9917 7.57544 10.7084 7.91711 10.7084H12.0838C12.4254 10.7084 12.7088 10.9917 12.7088 11.3334C12.7088 11.675 12.4254 11.9584 12.0838 11.9584Z"
                    fill="#748191" fillOpacity="0.6"/>
            </svg>
            <ConfigProvider
                theme={{
                    token: {
                        colorBgElevated: 'var(--tg-theme-bg-color)',
                    },
                }}
            >
                <Modal
                    open={isShow}
                    onCancel={onHide}
                    title={''}
                    width={320}
                    centered={true}
                    cancelText={t('cancel')}
                    okText={t('delete')}
                    closeIcon={<></>}
                    footer={[
                        <Row key={'1'} align={'middle'} justify={'space-between'}>
                            <Col span={11}>
                                <button
                                    onClick={onHide}
                                    className={`action__btn action__btn--contacts contact__remove ${theme.entity === 'dark' ? "action__btn--dark" : ""}`}>
                                    {t('cancel')}
                                </button>
                            </Col>
                            <Col span={11}>
                                <button
                                    onClick={onRemove}
                                    disabled={confirmLoading}
                                    className={'action__btn action__btn--balance contact__remove'}
                                >
                                    {t('delete')}
                                </button>
                            </Col>
                        </Row>
                    ]}
                >
                    <p className={`contact__text ${theme.entity === 'dark' ? 'contact__text--dark' : ''}`}>
                        {t('deleteConfirm', {value: name})}
                    </p>
                </Modal>
            </ConfigProvider>
        </>

    )
}

export default ContactRemove
