import {IContactForm, IContactFormState} from "../../interfaces/contacts/contacts";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: IContactFormState = {
    contactFields: {
        entity: {
            name: undefined,
            phone: undefined,
            icon: '/media/gallery-light.jpg',
            operator: undefined,
            id: undefined
        },
        isEdit: false,
        isFilled: false
    }
}


export const contactFieldsSelector = (state: { contactFields: IContactFormState }) => state.contactFields


const contactFieldsSlice = createSlice({
    name: 'contact fields',
    initialState,
    reducers: {
        setFields: (state, action: PayloadAction<IContactForm>) => {
            state.contactFields.entity = action.payload
            state.contactFields.isEdit = true
        },
        removeFields: (state) => {
            state.contactFields.isEdit = false
            state.contactFields.isFilled = false
            state.contactFields.entity = {
                phone: '',
                name: undefined,
                icon: '/media/gallery-light.jpg',
                operator: undefined,
                id: undefined
            }
        },
        resetOperator: (state) => {
          state.contactFields.entity = {
              ...state.contactFields.entity,
              operator: undefined
          }
          state.contactFields.isFilled = false
        },
        setFilled: (state, action: PayloadAction<boolean>) => {
            state.contactFields.isFilled = action.payload
        }
    }
})


export const {
    removeFields,
    setFields,
    setFilled,
    resetOperator
} = contactFieldsSlice.actions


export default contactFieldsSlice.reducer