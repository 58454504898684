import makeRequest from "../makeRequest";
import {IPayment, IPaymentRequest} from "../../interfaces/payment/payment";
import {user} from "../../constants/user";

export function createPaymentsApi(body: IPaymentRequest) {
    return makeRequest.post('/api/v1/transactions', body)
}


export function getPaymentsApi(page: number, phoneNumber?: string) {
    const params = new URLSearchParams()


    params.append('page', page.toString())

    params.append('page_size', '20')

    if (phoneNumber) {
        params.append('number', phoneNumber.replace('+', ''))
    }

    return makeRequest.get(`/api/v1/transactions/${user?.id}`, {
        params: params
    })
}

export function getPaymentApi (id: string) {
    const params = new URLSearchParams()
    params.append('id', id)
    return makeRequest.get(`/api/v1/transactions/${user?.id}`, {
        params: params
    })
}

export function updatePaymentApi(body: IPayment) {
    const params = new URLSearchParams()
    params.append('tg_id', user?.id?.toString())
    return makeRequest.patch(`/api/v1/transactions/${body.id}`, body, {
        params: params
    })
}