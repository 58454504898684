import React from 'react';
import {Col, Row, Spin} from "antd";

interface Props {

}

const ListLoader: React.FC<Props> = () => {
    return (
        <Row align={'middle'} justify={'center'}>
            <Col>
                <Spin/>
            </Col>
        </Row>
    );
};

export default ListLoader;