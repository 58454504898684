import React, {useEffect, useRef, useState} from 'react'
import './payment-detail.scss'
import {PaymentContact, PaymentHistory} from './components'
import {backButtonClickedOff, backButtonClickedOn, onShowBackBtn} from "../../utils/telegram-events";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../store/slices/theme-slice";
import {resetPayments, setPaginationPayments, setPayment, setPaymentError} from "../../store/slices/payment-slice";
import {getPaymentsApi} from "../../api/payments/payments";
import {getContactsApi} from "../../api/contacts/contacts";
import {getContact, resetContact} from "../../store/slices/contacts-slice";
import ShowToggler from "../../shared/show-toggler/show-toggler";
import {errorHandler} from "../../utils/error-handler";

interface Props {

}

const PaymentDetail: React.FC<Props> = () => {
    const navigate = useNavigate()
    const [isHide, setIsHide] = useState<boolean>(false)
    const mainRef = useRef<null | HTMLDivElement>(null)
    const {theme} = useSelector(themeSelector)
    const {id} = useParams()
    const dispatch = useDispatch()
    const setShow = () => setIsHide(false)

    const onToggle = () => setIsHide(!isHide)

    const setHide = () => setIsHide(true)


    useEffect(() => {
        const paymentsGet = () => {
                getPaymentsApi(1, id)
                    .then((response) => {
                        if (response?.data) {
                            dispatch(setPayment(response?.data?.result))
                            if (response.data.pagination) {
                                dispatch(setPaginationPayments(response.data.pagination))
                            }
                        }
                    }).catch((e) => {
                    errorHandler(e, () => dispatch(setPaymentError(e.response.data.error.body.message)))
                })
        }
        paymentsGet()
    }, [])

    useEffect(() => {


        const contactsGet = () => {
                getContactsApi('1', id)
                    .then((response) => {
                            if (response?.data?.result?.length) {
                                dispatch(getContact(response.data.result[0]))
                            } else {
                                dispatch(getContact(undefined))
                            }
                        }
                    )
        }
        contactsGet()

        return () => {
            dispatch(resetPayments())
            dispatch(resetContact())
        }
    }, [])


    useEffect(() => {

        const onRedirect = () => {
            navigate(-1)
        }

        backButtonClickedOn(onRedirect)
        onShowBackBtn()
        const onWheel = (e: WheelEvent) => {
            const dy = e.deltaY
            if (dy > 0) {
                setHide()
                return
            }

        }
        let touchstartY = 0
        let touchendY = 0
        const checkDirection = () => {
            if (touchendY < touchstartY) {
                setHide()
            }
        }


        const onTouchStart = (e: TouchEvent) => {
            touchstartY = e.changedTouches[0].screenY
        }

        const onTouchEnd = (e: TouchEvent) => {
            touchendY = e.changedTouches[0].screenY
            checkDirection()
        }


        mainRef.current?.addEventListener('wheel', onWheel)
        mainRef.current?.addEventListener('touchstart', onTouchStart)
        mainRef.current?.addEventListener('touchend', onTouchEnd)

        return () => {
            backButtonClickedOff(onRedirect)
            mainRef.current?.removeEventListener('wheel', onWheel)
            mainRef.current?.removeEventListener('touchstart', onTouchStart)
            mainRef.current?.removeEventListener('touchend', onTouchEnd)
        }
    }, [mainRef])


    return (
        <>
            <PaymentContact setShow={setShow} isHide={isHide}/>
            <div className={`divider ${theme.entity === 'dark' ? 'divider__dark' : ""}`}>
                <ShowToggler isHide={isHide} onToggle={onToggle}/>
            </div>
            <div className={`payment__history ${isHide ? 'payment__history--hide' : ''}`} ref={mainRef}>
                <PaymentHistory isHide={isHide}/>
            </div>
        </>
    )
}

export default PaymentDetail
