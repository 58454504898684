import {IContactGet, IContactItem, IContactState} from "../../interfaces/contacts/contacts";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPagination} from "../../interfaces/payment/payment";


const initialState: IContactState = {
    contacts: {
        entity: [],
        loaded: false,
        errors: {
            isError: false,
            message: ''
        },
        pagination: {
            nextPage: false,
            prevPage: false,
            page: 1,
            pageSize: 20,
            maxPage: 0,
            itemCount: 0
        }
    },
    contact: {
        entity: undefined,
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }
    }
}


export const contactsSelector = (state: { contacts: IContactState }) => state.contacts


const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContacts: (state, action: PayloadAction<IContactGet[]>) => {
            state.contacts.entity = action.payload
            state.contacts.loaded = true
            state.contacts.errors.isError = false
        },
        setContactsError: (state, action: PayloadAction<string>) => {
            state.contacts.loaded = true
            state.contacts.errors.isError = true
            state.contacts.errors.message = action.payload
        },
        addContact: (state, action: PayloadAction<IContactGet>) => {
            const newContacts: IContactGet[] = state.contacts.entity
            newContacts.unshift(action.payload)
            state.contacts.entity = newContacts
            state.contacts.loaded = true
            state.contacts.errors.isError = false
        },
        editContact: (state, action: PayloadAction<IContactGet>) => {
            const newContacts = state.contacts.entity.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload
                }
                return item
            })
            state.contacts.entity = newContacts
            state.contacts.loaded = true
            state.contacts.errors.isError = false
        },
        removeContact: (state, action: PayloadAction<number>) => {
            const newContacts = state.contacts.entity.filter((item) => item.id !== action.payload)
            state.contacts.entity = newContacts
            state.contacts.loaded = true
            state.contacts.errors.isError = false
        },
        getContact: (state, action: PayloadAction<IContactItem | undefined>) => {
            state.contact.entity = action.payload
            state.contact.loaded = true
            state.contact.errors.isError = false
        },
        getContactError: (state, action: PayloadAction<string>) => {
            state.contact.loaded = true
            state.contact.errors.isError = true
            state.contact.errors.message = action.payload
        },
        resetContact: (state) => {
            state.contact.entity = undefined
            state.contact.loaded = false
            state.contact.errors.isError = false
        },
        setPaginationContacts: (state, action: PayloadAction<IPagination>) => {
            state.contacts.pagination = action.payload
        },
        extendContacts: (state,action: PayloadAction<IContactGet[]>) => {
            const newState = state.contacts.entity.concat(action.payload)
            state.contacts.entity = newState
        },
        resetContacts: (state) => {
            state.contacts = initialState.contacts
        }
    }
})


export const {
    setContacts,
    setContactsError,
    addContact,
    editContact,
    removeContact,
    getContact,
    getContactError,
    resetContact,
    setPaginationContacts,
    extendContacts,
    resetContacts
} = contactsSlice.actions


export default contactsSlice.reducer