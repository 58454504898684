import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPayment, IPaymentFields, IPaymentFieldsState} from "../../interfaces/payment/payment";


const initialState: IPaymentFieldsState = {
    paymentFields: {
        entity: {
            phone: undefined,
            operator: undefined,
            icon: '/media/gallery-light.jpg'
        },
        result: undefined ,
        isFilled: false,
        isSet: false,
    }
}




export const paymentFieldSelector = (state: { paymentFields: IPaymentFieldsState }) => state.paymentFields


const paymentFieldsSlice = createSlice({
    name: 'payment fields',
    initialState,
    reducers: {
        setPaymentFields: (state, action: PayloadAction<IPaymentFields>) => {
            state.paymentFields.entity = action.payload
            state.paymentFields.isFilled = true
        },
        setIsSet: (state, action: PayloadAction< boolean>) => {
            state.paymentFields.isSet = action.payload
        },
        removePaymentFields: (state) => {
            state.paymentFields.entity = {
                phone: '',
                operator: undefined,
                icon: '/media/gallery-light.jpg'
            }
            state.paymentFields.isFilled = false
            state.paymentFields.isSet = false
        },
        setResult: (state, action: PayloadAction<IPayment>) => {
            state.paymentFields.result = action.payload
        },
        resetResult: (state) => {
            state.paymentFields.result = undefined
        },
        resetOperator: (state) => {
            state.paymentFields.entity.operator = undefined
            state.paymentFields.isSet = false
        },
    }
})






export const {
    setPaymentFields,
    removePaymentFields,
    setIsSet,
    setResult,
    resetResult,
    resetOperator
} = paymentFieldsSlice.actions


export default paymentFieldsSlice.reducer