import React from 'react'
import './transaction.scss'
import {Row} from "antd";
import {userColors} from "../../../../../../constants/user-colors";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {IPayment} from "../../../../../../interfaces/payment/payment";
import {resetPayments} from "../../../../../../store/slices/payment-slice";
import {operatorsSelector} from "../../../../../../store/slices/operator-slice";
import {setIsSet, setPaymentFields} from "../../../../../../store/slices/payment-form-slice";
import {formatDate} from "../../../../../../utils/dateFormatter";
import {findImage} from "../../../../../../utils/findImage";
import {useTranslation} from "react-i18next";

const utc = require('dayjs/plugin/utc')
const dayjs: any = require('dayjs')
dayjs.extend(utc)

interface Props {
    isContact?: boolean
    payment: IPayment
}

const Transaction: React.FC<Props> = ({payment}) => {
    const {theme} = useSelector(themeSelector)
    const navigate = useNavigate()
    const {phone_number, amount, created_at, users_contact} = payment
    const {pathname} = useLocation()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {operators} = useSelector(operatorsSelector)
    const foundOperator = operators.entity.find((item) => item.id === payment.operator_id)

    const month = t(`months.${dayjs(created_at).utc().format('MMM')}`)


    const redirect = () => {
        if (pathname === '/') {
            dispatch(resetPayments())
            navigate(`/payment/${payment.phone_number}`)
        }
        if (pathname === '/refill-balance') {
            dispatch(setPaymentFields({
                phone: phone_number,
                operator: foundOperator,
                icon: findImage(foundOperator, theme.entity)
            }))
            dispatch(setIsSet(true))
        }
    }



    return (
        <Row onClick={redirect} justify={'space-between'} className={`transaction`}
             style={{cursor: pathname === '/' || pathname === '/refill-balance' ? 'pointer' : 'default'}}>
            <div style={users_contact ? {
                background: users_contact.image,
                color: userColors.find(({backgroundColor}) => users_contact.image === backgroundColor)?.color
            } : undefined}
                 className={`${users_contact ? "transaction__contact" : "transaction__icon"}   ${theme.entity === 'dark' ? 'transaction__icon--dark' : ""}`}>
                {!users_contact ? <img
                    src={findImage(foundOperator, theme.entity)}
                    alt={""}/> : users_contact.name[0].toUpperCase()}
            </div>
            <div className={`transaction__info ${theme.entity === 'dark' ? 'transaction__info--dark' : ""}`}>
                <Row justify={'space-between'}>
                    <div className={'transaction__numbers'}>
                        <p className={`transaction__phone ${theme.entity === 'dark' ? 'transaction__phone--dark' : ''}`}>
                            {users_contact ? users_contact.name : phone_number}
                        </p>
                        <p className={`transaction__date ${theme.entity === 'dark' ? "transaction__date--dark" : ""} `}>
                            {formatDate(created_at, month)}
                        </p>
                    </div>
                    <div className={'transaction__sum'}>
                        {new Intl.NumberFormat('ru-RU').format(amount)} {t('sum').toLowerCase()}
                    </div>
                </Row>
            </div>
        </Row>
    )
}

export default Transaction
