import React, {useEffect} from 'react'
import './contacts-list.scss'
import Contact from "./components/contact/contact";
import {getContactsApi} from "../../../../api/contacts/contacts";
import {useDispatch, useSelector} from "react-redux";
import {
    contactsSelector,
    resetContacts,
    setContacts,
    setContactsError,
    setPaginationContacts
} from "../../../../store/slices/contacts-slice";
import Loader from "../../../../shared/loader/loader";
import NotFound from "../../../../shared/not-found/not-found";
import {errorHandler} from "../../../../utils/error-handler";

interface Props {

}


const ContactsList: React.FC<Props> = () => {
    const dispatch = useDispatch()
    const {contacts} = useSelector(contactsSelector)

    useEffect(() => {
        getContactsApi('1')
            .then((response) => {
                if (response?.data) {
                    dispatch(setContacts(response.data.result))
                   if (response.data.pagination) {
                       dispatch(setPaginationContacts(response.data.pagination))
                   }
                }
            }).catch((e) => {
                errorHandler(e, () => dispatch(setContactsError(e.response.data.error.body.message)))
        })
        return () => {
            dispatch(resetContacts)
        }
    }, [])


    if (!contacts.loaded) {
        return <Loader/>
    }

    return (
        <div className={'contacts'}>
            {contacts.entity.length > 0 ? contacts.entity.map((contact, index) => (
                <Contact contact={contact} index={index} key={contact.id}/>
            )): <NotFound/>}
        </div>
    )
}

export default ContactsList
