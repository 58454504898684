import React, {useState} from 'react';
import {ConfigProvider, Skeleton} from "antd";
import {LazyLoadImage} from "react-lazy-load-image-component";
import './lazy-load-image.scss'

interface Props {
    alt: string
    src: string
}

const LazyLoadImageComponent: React.FC<Props> = ({
                                                     src,
                                                     alt
                                                 }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const afterLoading = () => {
        setIsLoaded(true)
    }

    return (
        <>
            <LazyLoadImage
                afterLoad={afterLoading}
                alt={alt}
                src={src} // use normal <img> attributes as Props
            />
            {!isLoaded &&
                <span>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorFill: 'rgba(255,255,255,.06)'
                            },
                        }}
                    >
                    <Skeleton.Avatar className={'loader-skeleton'} active={true}
                                     style={{maxWidth: '100%', height: 'auto'}} size={'small'} shape={'square'}/>
                </ConfigProvider>
                </span>
            }
        </>
    );
};

export default LazyLoadImageComponent;