import {IContactGet} from "../../../../../../interfaces/contacts/contacts";
import {ContactRemove} from "./components";
import ContactEdit from "./components/contact-edit/contact-edit";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import React from "react";
import '../../../contacts-list/components/contact/contact.scss'
import {userColors} from "../../../../../../constants/user-colors";
import {Row} from "antd";
import {useNavigate} from "react-router-dom";

interface Props {
    contact: IContactGet
    index: number
}

const Contact: React.FC<Props> = (
    {
        contact,
        index
    }
) => {
    const {
        phone_number,
        name,
        image
    } = contact
    const navigate = useNavigate()
    const redirect = () => navigate(`/payment/${phone_number}`)
    const {theme} = useSelector(themeSelector)
    return (
        <Row justify={'space-between'}  className={'transaction'}>
            <div style={{
                background: image,
                color: userColors.find(({backgroundColor}) => backgroundColor === image)?.color
            }}
                 className={`transaction__contact ${theme.entity === 'dark' ? 'transaction__icon--dark' : ""}`}>
                {contact.name[0].toUpperCase()}
            </div>
            <div className={`transaction__info ${theme.entity === 'dark' ? 'transaction__info--dark' : ""}`}>
                <Row justify={'space-between'}>
                    <div onClick={redirect}  style={{cursor: 'pointer'}} className={'transaction__numbers'}>
                        <p className={`transaction__phone ${contact.name ? 'contact__phone' : ''} ${theme.entity === 'dark' ? 'contact__phone--dark' : ''}`}>
                           {name ? name : phone_number}
                        </p>
                        <p className={`transaction__date contact__date ${theme.entity === 'dark' ? "transaction__date--dark" : ""} `}>
                            {name ? phone_number : ''}
                        </p>
                    </div>
                    <div className={'contact__actions'}>

                            <ContactEdit contact={contact}/>
                            <ContactRemove contact={contact}/>

                    </div>
                </Row>
            </div>
        </Row>
    )
}

export default Contact
