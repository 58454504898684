import React from 'react'
import './payment-contact.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {setFields, setFilled} from "../../../../store/slices/contact-form-slice";
import {setIsSet, setPaymentFields} from "../../../../store/slices/payment-form-slice";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {operatorsSelector} from "../../../../store/slices/operator-slice";
import {contactsSelector} from "../../../../store/slices/contacts-slice";
import Loader from "../../../../shared/loader/loader";
import Icon from "./components/icon";
import {findImage, findInputImage} from "../../../../utils/findImage";
import Actions from "./components/actions";

interface Props {
    isHide: boolean
    setShow: () => void
}

const PaymentContact: React.FC<Props> = ({
                                             isHide,
                                             setShow
                                         }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {theme} = useSelector(themeSelector)
    const {id} = useParams()
    const {contact} = useSelector(contactsSelector)
    const {operators} = useSelector(operatorsSelector)
    const foundOperator = id ? operators.entity.find(({options}) => new RegExp(options.phoneNumber.validation).test(id)) : undefined
    const foundOperatorImg = findInputImage(foundOperator, theme.entity)


    const addContact = () => {
        dispatch(setFields({
            name: contact.entity ? contact.entity.name : '',
            phone: id,
            icon: findImage(foundOperator, theme.entity),
            operator: foundOperator,
            id: undefined
        }))
        dispatch(setFilled(true))
        navigate('/contacts')
    }


    const setContainerStyles = () => {
        if (!isHide) {
            return ''
        }
        return contact.entity ? 'payment-contact__hide' : 'payment-contact__unknown--hide'
    }


    const payContact = () => {
        dispatch(setPaymentFields({
            phone: id,
            operator: foundOperator,
            icon: findImage(foundOperator, theme.entity)
        }))
        dispatch(setIsSet(true))
        navigate('/refill-balance')
    }

    if (!contact.loaded) return <Loader/>

    return (
        <div onClick={setShow}
             className={`payment-contact ${contact.entity ? '' : 'payment-contact__unknown'}  ${setContainerStyles()}`}
             style={{cursor: isHide ? 'pointer' : 'default'}}>

            <Icon img={foundOperatorImg} isHide={isHide}/>
            <div>
                {contact.entity ?
                    <div className={`payment-contact__name ${isHide ? 'payment-contact__name--hide' : ''}`}>
                        {contact.entity.name}
                    </div> : null}
                <div
                    className={`payment-contact__phone ${theme.entity === 'dark' ? 'payment-contact__phone--dark' : ''} ${isHide ? 'payment-contact__phone--hide' : ''}`}>
                    {contact.entity ? <div
                        className={`payment-contact__operator ${theme.entity === 'dark' ? 'payment-contact__operator--dark' : ''}`}>
                        <img height={28} width={28} src={findInputImage(foundOperator, theme.entity)} alt={''}/>
                    </div> : null}

                    <p>
                        {id}
                    </p>
                </div>
            </div>
            <Actions addContact={addContact} payContact={payContact} isHide={isHide}/>
        </div>
    )
}

export default PaymentContact
