import React from 'react';
import {spacesRegEx} from "../../../../../../constants/regex";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {paymentFieldSelector} from "../../../../../../store/slices/payment-form-slice";
import {UseFormRegister} from 'react-hook-form'
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {Field, IPaymentForm} from "../../../../../../interfaces/payment/payment";


interface Props {
    clearErrors: (error: Field) => void
    setError: (type: Field, options: any) => void
    errors: any
    setValue: (name: Field, value: string) => void
    isHide: boolean
    register: UseFormRegister<IPaymentForm>
    amount: string | undefined
}

const SumInput: React.FC<Props> = ({
                                       clearErrors,
                                       setError,
                                       errors,
                                       setValue,
                                       isHide,
                                       register,
                                       amount
                                   }) => {
    const {t} = useTranslation()
    const {paymentFields} = useSelector(paymentFieldSelector)
    const {theme} = useSelector(themeSelector)


    
    return (
        <input
            type={'tel'}
            onInput={(e: any) => {
                const operator = paymentFields.entity.operator
                let min = operator ? operator.options.amount.min : 500
                let max = operator ? operator.options.amount.max : 1500000
                const value: number = Number(e.target.value.replace(spacesRegEx, ''))
                if (value && value < min || value && value > max) {
                    setError('amount', {
                        type: 'manual',
                        message: `${t('errors.sum', {
                            min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                            max: new Intl.NumberFormat('ru-RU').format(max).toString()
                        })}`
                    })
                }
                if (e.target.value[0] === '0') {
                    setError('amount', {
                        type: 'manual',
                        message: `${t('errors.sum', {
                            min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                            max: new Intl.NumberFormat('ru-RU').format(max).toString()
                        })}`
                    })
                }

            }}
            className={`sum__input ${isHide ? 'phone__input--small' : ''} ${errors?.amount ? "error__color" : ""} ${theme.entity === 'dark' ? 'phone__input--dark' : ""}`}
            {...register('amount', {
                required: {
                    value: true,
                    message: t('required')
                },
                onChange: (e => {
                    const operator = paymentFields.entity.operator
                    let min = operator ? operator.options.amount.min : 500
                    let max = operator ? operator.options.amount.max : 1500000
                    const value: number = Number(e.target.value.replace(spacesRegEx, ''))
                    if (e.target.value.trim().length) {
                        clearErrors('amount')
                    }
                    if (value && value < min || value && value > max) {
                        setError('amount', {
                            type: 'manual',
                            message: `${t('errors.sum', {
                                min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                                max: new Intl.NumberFormat('ru-RU').format(max).toString()
                            })}`
                        })
                    }
                    if (e.target.value[0] === '0') {
                        setError('amount', {
                            type: 'manual',
                            message: `${t('errors.sum', {
                                min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                                max: new Intl.NumberFormat('ru-RU').format(max).toString()
                            })}`
                        })
                    }

                    setValue('amount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))

                }),
                value: '0',
                onBlur: (e => {
                    const operator = paymentFields.entity.operator
                    let min = operator ? operator.options.amount.min : 500
                    let max = operator ? operator.options.amount.max : 1500000
                    if (e.target.value) {
                        if (e.target.value.trim().length) {
                            clearErrors('amount')
                        }
                        if (paymentFields)
                            if (e.target.value[0] === '0') {
                                setError('amount', {
                                    type: 'manual',
                                    message: `${t('errors.sum', {
                                        min: new Intl.NumberFormat('ru-RU').format(min).toString(),
                                        max: new Intl.NumberFormat('ru-RU').format(max).toString()
                                    })}`
                                })
                            }
                        setValue('amount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                    }
                })
            })}
            maxLength={8}
            style={{
                width: amount ? (amount.length > 8 ? amount.length - 1 : amount.length) + 'ch' : '16px'
            }}
        />
    );
};

export default SumInput;