import React from 'react'
import {IContact} from "../../../../../../../../interfaces/contacts/contacts";
import {useDispatch, useSelector} from "react-redux";
import {setFields, setFilled} from "../../../../../../../../store/slices/contact-form-slice";
import {operatorsSelector} from "../../../../../../../../store/slices/operator-slice";
import {themeSelector} from "../../../../../../../../store/slices/theme-slice";
import {findImage} from "../../../../../../../../utils/findImage";

interface Props {
    contact: IContact
}

const ContactAdd: React.FC<Props> = ({
                                         contact
                                     }) => {

    const dispatch = useDispatch()
    const {operators} = useSelector(operatorsSelector)

    const foundOperator = operators.entity.find(({options}) => new RegExp(options.phoneNumber.validation).test(contact.phone))

    const {theme} = useSelector(themeSelector)

    const onEdit = () => {
        dispatch(setFields({
            name: undefined,
            phone: contact.phone ? contact.phone : undefined,
            icon: findImage(foundOperator, theme.entity),
            operator: foundOperator,
            id: contact.id
        }))
        dispatch(setFilled(true))
    }

    return (
        <svg onClick={onEdit} style={{cursor: 'pointer'}} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.492 2.16663H6.50866C3.47533 2.16663 1.66699 3.97496 1.66699 7.00829V13.9833C1.66699 17.025 3.47533 18.8333 6.50866 18.8333H13.4837C16.517 18.8333 18.3253 17.025 18.3253 13.9916V7.00829C18.3337 3.97496 16.5253 2.16663 13.492 2.16663ZM13.3337 11.125H10.6253V13.8333C10.6253 14.175 10.342 14.4583 10.0003 14.4583C9.65866 14.4583 9.37533 14.175 9.37533 13.8333V11.125H6.66699C6.32533 11.125 6.04199 10.8416 6.04199 10.5C6.04199 10.1583 6.32533 9.87496 6.66699 9.87496H9.37533V7.16663C9.37533 6.82496 9.65866 6.54163 10.0003 6.54163C10.342 6.54163 10.6253 6.82496 10.6253 7.16663V9.87496H13.3337C13.6753 9.87496 13.9587 10.1583 13.9587 10.5C13.9587 10.8416 13.6753 11.125 13.3337 11.125Z"
                fill="#748191" fillOpacity="0.6"/>
        </svg>
    )
}

export default ContactAdd
