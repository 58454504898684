import React from 'react'
import './loader.scss'
import {Spin} from "antd";

interface Props {

}

const Loader: React.FC<Props> = () => {
    return (
        <div className={'loader'}>
            <Spin className={'loader__spin'}/>
        </div>
    )
}

export default Loader
