import {ICountry, ICountryState} from "../../interfaces/countries/countries";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: ICountryState = {
    countries: {
        entity: [],
        loaded: false,
        errors: {
            isError :false,
            message: ''
        }
    }
}



export const countriesSelector = (state: {countries: ICountryState}) => state.countries


const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers:{
        getCountries: (state, action: PayloadAction<ICountry[]>) => {
            state.countries.entity = action.payload
            state.countries.errors.isError = false
            state.countries.loaded = true
        }
    }
})


export const {getCountries} = countriesSlice.actions


export default countriesSlice.reducer