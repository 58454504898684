import React from 'react'
import {IContactGet} from "../../../../../../../../interfaces/contacts/contacts";
import {useDispatch, useSelector} from "react-redux";
import {setFields, setFilled} from "../../../../../../../../store/slices/contact-form-slice";
import {operatorsSelector} from "../../../../../../../../store/slices/operator-slice";
import {themeSelector} from "../../../../../../../../store/slices/theme-slice";
import {findImage} from "../../../../../../../../utils/findImage";

interface Props {
    contact: IContactGet
}

const ContactEdit: React.FC<Props> = ({
                                          contact
                                      }) => {

    const dispatch = useDispatch()

    const {operators} = useSelector(operatorsSelector)

    const foundOperator = operators.entity.find(({options}) => new RegExp(options.phoneNumber.validation).test(contact.phone_number))

    const {theme} = useSelector(themeSelector)


    const onEdit = () => {
        dispatch(setFields({
            name: contact.name,
            phone: contact.phone_number,
            id: contact.id,
            icon: findImage(foundOperator, theme.entity),
            operator: foundOperator
        }))
        dispatch(setFilled(true))
    }

    return (
        <svg onClick={onEdit} style={{cursor: 'pointer'}} width="20" height="21" viewBox="0 0 20 21" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 18.8334H2.5C2.15833 18.8334 1.875 18.55 1.875 18.2084C1.875 17.8667 2.15833 17.5834 2.5 17.5834H17.5C17.8417 17.5834 18.125 17.8667 18.125 18.2084C18.125 18.55 17.8417 18.8334 17.5 18.8334Z"
                fill="#748191" fillOpacity="0.6"/>
            <path
                d="M15.8505 3.40005C14.2338 1.78338 12.6505 1.74172 10.9921 3.40005L9.9838 4.40838C9.90047 4.49172 9.86714 4.62505 9.90047 4.74172C10.5338 6.95005 12.3005 8.71672 14.5088 9.35005C14.5421 9.35838 14.5755 9.36672 14.6088 9.36672C14.7005 9.36672 14.7838 9.33338 14.8505 9.26672L15.8505 8.25838C16.6755 7.44172 17.0755 6.65005 17.0755 5.85005C17.0838 5.02505 16.6838 4.22505 15.8505 3.40005Z"
                fill="#748191" fillOpacity="0.6"/>
            <path
                d="M13.0079 10.1083C12.7663 9.99163 12.5329 9.87496 12.3079 9.74163C12.1246 9.63329 11.9496 9.51663 11.7746 9.39163C11.6329 9.29996 11.4663 9.16663 11.3079 9.03329C11.2913 9.02496 11.2329 8.97496 11.1663 8.90829C10.8913 8.67496 10.5829 8.37496 10.3079 8.04163C10.2829 8.02496 10.2413 7.96663 10.1829 7.89163C10.0996 7.79163 9.95795 7.62496 9.83295 7.43329C9.73295 7.30829 9.61628 7.12496 9.50795 6.94163C9.37461 6.71663 9.25795 6.49163 9.14128 6.25829C8.98832 5.93051 8.5581 5.83314 8.30233 6.08891L3.61628 10.775C3.50795 10.8833 3.40795 11.0916 3.38295 11.2333L2.93295 14.425C2.84961 14.9916 3.00795 15.525 3.35795 15.8833C3.65795 16.175 4.07461 16.3333 4.52461 16.3333C4.62461 16.3333 4.72461 16.325 4.82461 16.3083L8.02461 15.8583C8.17461 15.8333 8.38295 15.7333 8.48295 15.625L13.1767 10.9312C13.4274 10.6805 13.3332 10.2492 13.0079 10.1083Z"
                fill="#748191" fillOpacity="0.6"/>
        </svg>

    )
}

export default ContactEdit
