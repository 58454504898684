import React, {useState} from 'react'
import './search-operators.scss'
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {useTranslation} from "react-i18next";

interface Props {
    isDrawer?: boolean
    filterData: (searchStr: string) => void
}

const SearchOperators: React.FC<Props> = ({
                                              isDrawer,
                                              filterData
                                          }) => {

    const [search, setSearch] = useState<string>('')
    const {t} = useTranslation()
    const {theme} = useSelector(themeSelector)


    const clearSearch = () => {
        setSearch('')
        filterData('')
    }

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value.trim())
        filterData(e.currentTarget.value.trim())
    }


    return (
        <div className={`search ${isDrawer ? 'search__small' : ''}`}>
            <input type={'search'} placeholder={`${t('search')}`} value={search} onChange={onChange}
                   className={`search__input ${theme.entity === 'dark' ? 'search__input--dark' : ""}`}/>
            {search.length > 0 ?
                <svg onClick={clearSearch} style={{cursor: 'pointer'}} width="24" height="24" viewBox="0 0 24 24"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.2426 6.34314L6.34309 16.2426C5.95257 16.6332 5.95257 17.2663 6.34309 17.6569C6.73362 18.0474 7.36678 18.0474 7.75731 17.6569L17.6568 7.75736C18.0473 7.36683 18.0473 6.73367 17.6568 6.34314C17.2663 5.95262 16.6331 5.95262 16.2426 6.34314Z"
                        fill="#676A6F"/>
                    <path
                        d="M17.6569 16.2426L7.7574 6.34315C7.36688 5.95262 6.73371 5.95262 6.34319 6.34315C5.95266 6.73367 5.95266 7.36684 6.34319 7.75736L16.2427 17.6569C16.6332 18.0474 17.2664 18.0474 17.6569 17.6569C18.0474 17.2663 18.0474 16.6332 17.6569 16.2426Z"
                        fill="#676A6F"/>
                </svg>

                : <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C8.57234 14 10.0236 13.4816 11.1922 12.6064L15.293 16.7072C15.6835 17.0977 16.3167 17.0977 16.7072 16.7072C17.0977 16.3167 17.0977 15.6835 16.7072 15.293L12.6064 11.1922C13.4816 10.0236 14 8.57234 14 7C14 3.13401 10.866 0 7 0ZM2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7Z"
                          fill="#676A6F"/>
                </svg>}

        </div>
    )
}

export default SearchOperators
