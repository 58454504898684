import makeRequest from "../makeRequest";
import {user} from "../../constants/user";
import {IContactEdit, IContactGet, IContactItem} from "../../interfaces/contacts/contacts";

export function getContactsApi(page:string, phone_number?: string) {
    const params = new URLSearchParams()



    if (phone_number) {
        params.append('number', phone_number.replace('+', ''))
    }
    if (page) {
        params.append('page', page)
    }
    params.append('page_size', '20')
    return makeRequest.get(`/api/v1/contacts/${user?.id}`, {
        params: params
    })
}


export function createContact(data: IContactItem) {
    return makeRequest.post(`/api/v1/contacts/`, data)
}

export function editContactApi(data: IContactEdit, id: number) {
    const params = new URLSearchParams()

    if(data.tg_id) {
        params.append('tg_id', data.tg_id)
    }

    const actualData = {
        name: data.name,
        phone_number: data.phone_number,
        status: data.status
    }
    return makeRequest.patch(`/api/v1/contacts/${id}`, actualData, {
        params: params
    })
}


export function removeContactApi(id: number) {
    return makeRequest.patch(`/api/v1/contacts/${id}?tg_id=${user?.id}`, {status: -1})
}