import React from 'react'
import {IContact} from "../../../../../../interfaces/contacts/contacts";
import {userColors} from "../../../../../../constants/user-colors";
import '../../../contacts-list/components/contact/contact.scss'
import {Row} from "antd";
import ContactEdit from "../../../contacts-list/components/contact/components/contact-edit/contact-edit";
import {ContactRemove} from "../../../contacts-list/components/contact/components";
import ContactAdd from "../../../contacts-list/components/contact/components/contact-add/contact-add";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../../../store/slices/theme-slice";
import {IPayment} from "../../../../../../interfaces/payment/payment";
import {operatorsSelector} from "../../../../../../store/slices/operator-slice";
import {formatDate} from "../../../../../../utils/dateFormatter";
import {findInputImage} from "../../../../../../utils/findImage";
import {useTranslation} from "react-i18next";

const utc = require('dayjs/plugin/utc')
const dayjs: any = require('dayjs')
dayjs.extend(utc)

interface Props {
    payment: IPayment
    index: number
}

const ContactsHistoryItem: React.FC<Props> = (
    {
        payment,
        index
    }
) => {
    const {
        created_at,
        phone_number,
        operator_id,
        users_contact
    } = payment
    const {operators} = useSelector(operatorsSelector)

    const {t} = useTranslation()

    const users_info: IContact = {
        phone: phone_number,
        name: undefined,
        date: created_at,
    }
    const foundOperator = operators.entity.find(({options}) => new RegExp(options.phoneNumber.validation).test(phone_number))

    const month = t(`months.${dayjs(created_at).utc().format('MMM')}`)


    const {theme} = useSelector(themeSelector)
    return (
        <Row justify={'space-between'} className={'transaction'}>
            <div style={users_contact ?{
                background: users_contact.image,
                color: userColors.find(({backgroundColor}) => backgroundColor === users_contact.image)?.color
            } : undefined}
                 className={`${users_contact ? 'transaction__contact' : 'transaction__icon'} ${theme.entity === 'dark' ? 'transaction__icon--dark' : ""}`}>
                {users_contact?  users_contact.name[0].toUpperCase() : <img src={findInputImage(foundOperator, theme.entity)} alt={''}/>}
            </div>
            <div className={`transaction__info ${theme.entity === 'dark' ? 'transaction__info--dark' : ""}`}>
                <Row justify={'space-between'}>
                    <div className={'transaction__numbers'}>
                        <p className={`transaction__phone ${operator_id ? 'contact__phone' : ''} ${theme.entity === 'dark' ? 'contact__phone--dark' : ''}`}>
                            {users_contact ? users_contact.name : phone_number}
                        </p>
                        <p className={`transaction__date contact__date ${theme.entity === 'dark' ? "transaction__date--dark" : ""} `}>
                            {formatDate(payment.created_at, month)}
                        </p>
                    </div>
                    <div className={'contact__actions'}>
                        {users_contact ? <>
                            <ContactEdit contact={users_contact}/>
                            <ContactRemove contact={users_contact}/>
                        </> :  <ContactAdd contact={users_info}/>}
                    </div>
                </Row>
            </div>
        </Row>
    )
}

export default ContactsHistoryItem
