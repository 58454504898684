import React from 'react'
import './operators.scss'
import {Col, Row} from "antd";
import {useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import LazyLoadImageComponent from "../../../../shared/lazy-load-image/lazy-load-image";
import {operatorsSelector} from "../../../../store/slices/operator-slice";
import Loader from "../../../../shared/loader/loader";


interface Props {
    isHide: boolean
}


const Operators: React.FC<Props> = ({
                                        isHide
                                    }) => {
    const {operators} = useSelector(operatorsSelector)

    const {theme} = useSelector(themeSelector)

    if (!operators.loaded) return <Loader/>



    return (
        <Row align={'middle'} className={`operators ${isHide ? 'operators__hide' : ''}`} justify={'center'}>
            {operators.entity.filter(({type}) => type === 1).map(({
                                title,
                                images,
                id
                            }) => (
                <Col key={id} className={`operators__item ${theme.entity === 'dark' ? 'operators__item--dark' : ""}`}>
                    <LazyLoadImageComponent alt={title} src={theme.entity === 'dark' ? images.dark : images.light}/>
                </Col>
            ))}
        </Row>
    )
}


export default Operators
