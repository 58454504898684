import React, {useEffect, useRef, useState} from 'react'
import './refill-balance.scss'
import {useNavigate} from "react-router-dom";
import {backButtonClickedOff, backButtonClickedOn, onShowBackBtn} from "../../utils/telegram-events";
import Transactions from "../index/components/transactions/transactions";
import {RefillForm} from "./components";
import {useDispatch, useSelector} from "react-redux";
import {paymentFieldSelector, removePaymentFields} from "../../store/slices/payment-form-slice";
import {themeSelector} from "../../store/slices/theme-slice";
import ShowToggler from "../../shared/show-toggler/show-toggler";

interface Props {

}

const RefillBalance: React.FC<Props> = () => {

    const [isHide, setIsHide] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {paymentFields} = useSelector(paymentFieldSelector)

    const onToggle = () => setIsHide(!isHide)
    const setShow = () => setIsHide(false)

    const mainRef = useRef<null | HTMLDivElement>(null)
    const {theme} = useSelector(themeSelector)
    useEffect(() => {
        const onRedirect = () => {

            if (paymentFields.isFilled) {
                dispatch(removePaymentFields())
                return
            }
            navigate(-1)
        }
        onShowBackBtn()
        backButtonClickedOn(onRedirect)

        return () => {
            backButtonClickedOff(onRedirect)
        }
    }, [paymentFields.isFilled])

    useEffect(() => {

        const onWheel = (e: WheelEvent) => {
            const dy = e.deltaY
            if (dy > 0) {
                setIsHide(true)
            }

        }
        let touchstartY = 0
        let touchendY = 0
        const checkDirection = () => {
            if (touchendY < touchstartY) {
                setIsHide(true)
            }
        }

        const onTouchStart = (e: TouchEvent) => {
            touchstartY = e.changedTouches[0].screenY
        }

        const onTouchEnd = (e: TouchEvent) => {
            touchendY = e.changedTouches[0].screenY
            checkDirection()
        }


        mainRef.current?.addEventListener('wheel', onWheel)
        mainRef.current?.addEventListener('touchstart', onTouchStart)
        mainRef.current?.addEventListener('touchend', onTouchEnd)


        return () => {
            mainRef.current?.removeEventListener('wheel', onWheel)
            mainRef.current?.removeEventListener('touchstart', onTouchStart)
            mainRef.current?.removeEventListener('touchend', onTouchEnd)
        }
    }, [])

    return (
        <div className={'refill-balance'}>
            <RefillForm onShow={setShow} isHide={isHide}/>
            <div className={`divider ${theme.entity === 'dark' ? 'divider__dark' : ""}`}>
                <ShowToggler onToggle={onToggle} isHide={isHide}/>

            </div>
            <div className={`refill__transactions ${isHide ? 'refill__transactions--hide' : ''}`} ref={mainRef}>
                <Transactions isForm={true} isHide={isHide}/>
            </div>

        </div>
    )
}

export default RefillBalance
