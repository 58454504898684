import {InvoiceStatus} from "../interfaces/payment/payment";

export const onShowBackBtn = () => {
    return window.Telegram.WebApp.BackButton.show()
}

export const onHideBackBtn = () => {
    return window.Telegram.WebApp.BackButton.hide()
}

export const platformType = () => {
    return window.Telegram.WebApp.platform
}


export const backButtonClickedOn = (callBack: () => void) => {
    return window.Telegram.WebApp.onEvent('backButtonClicked', callBack)
}

export const backButtonClickedOff = (callBack: () => void) => {
    return window.Telegram.WebApp.offEvent('backButtonClicked', callBack)
}


export const openInvoice = (invoiceLink: string, callBack: (status: InvoiceStatus) => void) => {
    return window.Telegram.WebApp.openInvoice(invoiceLink, callBack)
}

export const onThemeEvent = (callBack: () => void) => {
    return window.Telegram.WebApp.onEvent('themeChanged', callBack)
}

export const offThemeEvent = (callBack: () => void) => {
    return window.Telegram.WebApp.offEvent('themeChanged', callBack)
}

export const onReady = () => {
    return window.Telegram.WebApp.ready()
}

export const enableCloseConfirmation = () => window.Telegram.WebApp.enableClosingConfirmation()


export const onOpenLink = (link: string) => {
    return window.Telegram.WebApp.openLink(link)
}