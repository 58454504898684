import React, {useEffect, useState} from 'react'
import './contacts-info.scss'
import {ConfigProvider, Tabs, TabsProps} from "antd";
import {useTranslation} from "react-i18next";
import {ContactsHistory, ContactsList} from "../";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../../../store/slices/theme-slice";
import {getContactsApi} from "../../../../api/contacts/contacts";
import {
    contactsSelector,
    extendContacts,
    resetContact,
    setContactsError,
    setPaginationContacts
} from "../../../../store/slices/contacts-slice";
import InfiniteScroll from "react-infinite-scroll-component";
import {getPaymentsApi} from "../../../../api/payments/payments";
import {
    extendPayments,
    paymentsSelector,
    resetPayments,
    setPaginationPayments,
    setPaymentError
} from "../../../../store/slices/payment-slice";
import ListLoader from "../../../../shared/loader/list-loader";
import {errorHandler} from "../../../../utils/error-handler";

interface Props {
    isHide: boolean
    setShow: () => void
    setHide: () => void
}

const ContactsInfo: React.FC<Props> = ({
                                           isHide,
                                           setHide,
                                           setShow
                                       }) => {
    const {theme} = useSelector(themeSelector)
    const {t} = useTranslation()
    const {contacts} = useSelector(contactsSelector)
    const dispatch = useDispatch()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {payments} = useSelector(paymentsSelector)


    const fetchContacts = () => {

        setConfirmLoading(true)
        getContactsApi((contacts.pagination.page + 1).toString())
            .then((response) => {
                if (response?.data) {
                    dispatch(extendContacts(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationContacts(response.data.pagination))
                    }
                }
            }).catch((e) => {
                errorHandler(e, () =>  dispatch(setContactsError(e.response.data.error.body.message)))
        }).finally(() => setConfirmLoading(false))
    }

    const fetchHistory = () => {
        setConfirmLoading(true)
        getPaymentsApi(payments.pagination.page + 1)
            .then((response) => {
                if (response?.data) {
                    dispatch(extendPayments(response.data.result))
                    if (response.data.pagination) {
                        dispatch(setPaginationPayments(response.data.pagination))
                    }
                }
            }).catch((e) => {
                errorHandler(e, () =>  dispatch(setPaymentError(e.response.data.error.body.message)))

        }).finally(() => setConfirmLoading(false))
    }


    useEffect(() => {


        const tabContact: any = document.querySelector('.ant-tabs-content-holder')
        let touchstartY = 0
        let touchendY = 0
        const onWheel = (e: WheelEvent) => {
            const dy = e.deltaY
            if (dy > 0) {
                setHide()
            }


        }

        const checkDirection = () => {
            if (touchendY < touchstartY) {
                setHide()
            }
        }

        const onTouchStart = (e: TouchEvent) => {
            touchstartY = e.changedTouches[0].screenY
        }

        const onTouchEnd = (e: TouchEvent) => {
            touchendY = e.changedTouches[0].screenY
            checkDirection()
        }


        tabContact?.addEventListener('wheel', onWheel)
        tabContact?.addEventListener('touchstart', onTouchStart)
        tabContact?.addEventListener('touchend', onTouchEnd)
        return () => {
            dispatch(resetContact())
            dispatch(resetPayments())
            tabContact?.removeEventListener('wheel', onWheel)
            tabContact?.removeEventListener('touchstart', onTouchStart)
            tabContact?.removeEventListener('touchend', onTouchEnd)
        }
    }, [])


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('contacts.title'),
            children:
                <InfiniteScroll className={'contact__scroll'} next={fetchContacts}
                                hasMore={contacts.pagination.itemCount !== contacts.entity.length}
                                loader={<>
                                </>}
                                dataLength={contacts.entity.length}>
                    <ContactsList/>
                    {confirmLoading && <ListLoader/>}
                </InfiniteScroll>
        },
        {
            key: '2',
            label: t('history'),
            children: <InfiniteScroll  className={'contact__scroll'} next={fetchHistory}
                                      hasMore={payments.pagination.itemCount !== payments.entity.length}
                                      loader={<>
                                      </>}
                                      dataLength={payments.entity.length}>
                <ContactsHistory/>
                {confirmLoading && <ListLoader/>}
            </InfiniteScroll>,
        },
    ];


    const renderTabBar: TabsProps['renderTabBar'] = (Props, DefaultTabBar) => (
        <div>
            <DefaultTabBar {...Props} />
        </div>
    );

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorText: theme.entity === 'dark' ? '#778892' : '#878C8F',
                    colorPrimary: theme.entity === 'dark' ? "#50A7EA" : "#3995D4",
                    colorPrimaryActive: theme.entity === 'dark' ? "#50A7EA" : "#3995D4",
                    fontSize: 15,
                    lineWidth: 1,
                    margin: 0,
                    fontFamily: 'Roboto, sans-serif;',
                    colorBorderSecondary: theme.entity === 'dark' ? '#0B1016' : '#D9D9D9',
                    padding: 12
                },
            }}
        >
            <Tabs className={`tabs ${isHide ? 'tabs__hide' : ''}`}
                  defaultActiveKey="1" items={items} renderTabBar={renderTabBar}
            />
        </ConfigProvider>

    )
}

export default ContactsInfo
